import React from 'react';
import './Mission.css';

const Mission = () => {
    return (
        <div className=' bg-[#e8f3f8] dark:bg-slate-600'>
            <div className=' pt-16 pb-12 '>
                
            <div>
                <h1 className=' text-[#A13744] md:text-5xl  font-bold playrify text-center text-xl dark:text-gray-100'>
                Our Mission 
                
                </h1>
                <div className='flex justify-center mt-3'>
            <hr className='w-[5%] border-t-[2px] border-gray-500 dark:border-gray-100' />
            </div>

            </div>
           

                <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4 lg:mt-8 w-[95%] lg:w-[80%] mx-auto '>

                    <div className='border-[1px] border-[#CA4757] p-4 shadow-lg hover:bg-[#26364F] hover:text-white hover:border-white cursor-pointer hover:duration-300  font-medium hover-effect  dark:text-gray-100 dark:border-gray-100'>

                        <p className='text-justify tracking-tighter leading-relaxed'>
                        The mission of the Brooklyn Institute of Vocational Training is to provide comprehensive training and empower individuals who want to pursue a career as a home health aide.
                        </p>

                    </div>
                    <div className='border-[1px] border-[#CA4757] p-4 shadow-lg hover:bg-[#26364F] hover:text-white hover:border-white cursor-pointer hover:duration-300 font-medium hover-effect dark:text-gray-100 dark:border-gray-100'>

                        <p className='text-justify tracking-tighter  leading-relaxed'>
                        Our high-quality training ensures that each student who graduates from our school can provide the best care and support to individuals who require assistance with activities of daily living.
                        </p>

                    </div>
                    <div className='border-[1px] border-[#CA4757] p-4 shadow-lg hover:bg-[#26364F] hover:text-white hover:border-white cursor-pointer hover:duration-300 font-medium hover-effect dark:text-gray-100 dark:border-gray-100'>

                        <p className='text-justify leading-relaxed tracking-tighter '>
                        Additionally, the school's mission is to adhere to state regulations, promote ethical behavior, and promote the highest standards of professionalism among its graduates.
                        </p>

                    </div>
                    <div className='border-[1px] border-[#CA4757] p-4 shadow-lg hover:bg-[#26364F] hover:text-white hover:border-white cursor-pointer hover:duration-300 font-medium hover-effect dark:text-gray-100 dark:border-gray-100'>

                        <p className='text-justify leading-relaxed tracking-tighter '>
                        We offer hands-on training, recognized certification, and job placement assistance to graduates to ensure we meet the requirements for qualified HHAs in the industry.
                        </p>

                    </div>

                    {/* <div className='lg:col-span-4 col-span-7 px-8 mission-text mt-[10vh] text-white leading-relaxed open-sans'>

                    <p className=' text-justify flex items-center gap-3  mt-2' data-aos="fade-up" data-aos-duration="500"> <GiPlainSquare className='w-3 h-w-3 text-white' /> The mission of the Brooklyn Institute of Vocational Training is to provide comprehensive training and empower individuals who want to pursue a career as a home health aide. </p>
            <p className=' text-justify flex items-center gap-3  mt-2' data-aos="fade-up" data-aos-duration="1000"> <GiPlainSquare className='w-3 h-w-3 text-white' /> Our high-quality training ensures that each student who graduates from our school can provide the best care and support to individuals who require assistance with activities of daily living. </p>
            <p className=' text-justify flex items-center gap-3  mt-2' data-aos="fade-up" data-aos-duration="1500"> <GiPlainSquare className='w-3 h-w-3 text-white' /> Additionally, the school's mission is to adhere to state regulations, promote ethical behavior, and promote the highest standards of professionalism among its graduates. </p>
            <p className=' text-justify flex items-center gap-3  mt-2' data-aos="fade-up" data-aos-duration="2000"> <GiPlainSquare className='w-3 h-w-3 text-white' /> We offer hands-on training, recognized certification, and job placement assistance to graduates to ensure we meet the requirements for qualified HHAs in the industry.  </p>
                        

                    </div>
                    <div className='lg:col-span-3 col-spa'>
                        <img src={rightPic} alt="mission-page" className='w-full h-[55vh]' />

                    </div> */}
                   

                </div>

            </div>
            
        </div>
    );
};

export default Mission;