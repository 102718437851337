import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLoaderData, useNavigate } from "react-router-dom";

const UpdateCountDown = () => {
  const countdown = useLoaderData();
  const navigate = useNavigate();
  // console.log(countdown);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const countDownHandler = (data) => {
    let inputDate = data.date;

    // Convert to desired format
    let formattedDate = new Date(inputDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const classInfo = {
      classTitle: data.classTitle,
      date: formattedDate,
      id: countdown?._id
    };

    fetch("https://brooklyn-server-v4.vercel.app/classes/updateCountDown", {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(classInfo),
    })
      .then((res) => res.json())
      .then((data) => {
        //    console.log(data)

        if (data.acknowledged) {
          toast.success("Countdown updated Successfully");
          reset();
          navigate("/")
        }
      });
  };

  let inputDate = countdown?.date;
  function formatDate(inputDate) {
    const months = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const [month, day, year] = inputDate.split(" ");
    const formattedDate = `${year}-${months[month]}-${day}`;

    return formattedDate;
  }

  let formattedDate = formatDate(inputDate);

  //   const update= "2023-08-21";

  const finalData = formattedDate.slice(0, 10);
  // console.log(finalData);

  return (
    <div className="pt-5 classroom-bg" id="contact-form">
      <section className="flex justify-center items-center pb-8 mt-3 mb-12 rounded w-11/12  md:w-[80%] mx-auto big-screen1">
        <div className="w-full  " data-aos="fade-up" data-aos-duration="2000">
          <form
            onSubmit={handleSubmit(countDownHandler)}
            className="max-w-xl md:max-w-3xl   ml-auto bg-gray-50 p-8 rounded-md shadow-xl dark:bg-slate-600 "
          >
            <h1 className="text-2xl text-gray-600 text-center Poppins font-semibold dark:text-gray-200">
              
              Update The Countdown Replace Of
            
            </h1>
            
            <p className="text-xl text-center Poppins font-semibold my-3 dark:text-gray-200 text-primary">
            {countdown?.classTitle} at {countdown?.date}
            </p>
          

            <div className="">
              <div className="space-y-0.5   text-sm">
                <label
                  htmlFor="firstName"
                  className="block  dark:text-gray-200"
                >
                  Class Schedule
                </label>
                <input
                  defaultValue={countdown?.classTitle}
                  type="text"
                  {...register("classTitle", {
                    required: "Class Schedule is required",
                  })}
                  id="Schedule"
                  placeholder="Class Schedule "
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                                            focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />

                {errors.classTitle && (
                  <p className="text-red-600">{errors.classTitle.message}</p>
                )}
              </div>
            </div>

            <hr className="my-3" />

            <div className="space-y-0.5   text-sm ">
              <div>
                <label
                  htmlFor="firstName"
                  className="block  dark:text-gray-200"
                >
                  Class Start Date
                </label>
                <input
                  defaultValue={finalData}
                  type="date"
                  {...register("date", {
                    required: "Date is Required",
                  })}
                  id="registration"
                  placeholder="Registration Fee: $"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />

                {errors.date && (
                  <p className="text-red-600 mt-1">{errors.date.message}</p>
                )}
              </div>
            </div>

            <button className="block w-full p-3 text-center rounded-sm  bg-primary hover:bg-secondary text-white mt-6">
              Upload
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default UpdateCountDown;
