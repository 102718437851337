import React from "react";
import { Link } from "react-router-dom";
import videoBg from "../../Assets/bannerVideo-final.mp4";
import Marquee from "../../Shared/Marquee/Marquee";

const Banner = () => {

  

  return (
    <div>
      <div className="main relative md:block hidden">
        <div className="overlay"></div>
        <video id="video" src={videoBg} autoPlay loop muted />
        <div className="content title-section absolute top-0   w-[100%] mt-[40vh] lg:mt-[30vh] banner-text ">
          <h1 className="w-[95%] institute-title  lg:w-[90%] md:text-4xl text-2xl mx-auto lg:text-[55px] text-white text-center playrify font-semibold leading-relaxed lg:leading-snug main-text ">
            "Brooklyn Institute Of Vocational Training <br />
            <p className="lg:text-[45px]">Home Health Aide Certification "</p>
            <p className="lg:text-5xl text-2xl text-white font-extrabold ">
              <a href="tel:+1929-386-0092">929-386-0092</a>
            </p>
            <br />
            <p className="lg:text-2xl -mt-8 lg:-mt-12 text-xl">
              Changing Lives, One Student at a Time.
            </p>
          </h1>

          <div className="flex justify-center mt-8">
            <Link
              to="/classes"
              className="rounded-full bg-[#CA4757] text-white font-semibold md:text-lg px-5 py-4 md:px-9 md:py-5 hover:bg-white hover:text-[#CA4757] transition duration-300 hover:font-medium hover:border-[#CA4757] uppercase hover-effect shadow-lg"
            >
              Upcoming Classes
            </Link>
          </div>
        </div>
      </div>

      <div className="mobile-banner min-h-[72vh] md:hidden relative">
      
      </div>

    {/* marquee tag start */}

    <Marquee></Marquee>


    {/* marquee tag end  */}

    </div>
  );
};

export default Banner;
