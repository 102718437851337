import React, { useRef, useState, useEffect } from 'react';
import './Videoplayer.css'
import { BiFullscreen } from 'react-icons/bi';
import VideoModal from './VideoModal';

const VideoPlayer = ({ selectedVideo }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [modalVideo, selectModalVideo] = useState(false)
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const enableAutoplay = () => {
    if (videoRef.current) {
      videoRef.current.setAttribute('autoplay', true);
    }
  };

  const modalHandler = (video)=>{
    selectModalVideo(video);

  }


  return (
    <div className=' shadow-lg rounded-md  w-[95%] md:w-full p-4 mx-auto relative ' id='video-color'



    >
      {/* <label
        onClick={() => modalHandler(selectedVideo)}
        ref={videoRef}
        htmlFor="show-videos"
        className="cursor-pointer absolute top-4 right-3"
      >
        <BiFullscreen className=' text-2xl text-gray-300' />
      </label> */}

      <div
        className=" md:h-[520px] h-[400px]  flex items-center justify-center  cursor-pointer"
        id='video-player'
        onClick={togglePlay}
        onMouseEnter={enableAutoplay}
      >
        <video
          ref={videoRef}
          disablefullscreen
          controls
          className="h-full md:w-auto w-full"
          src={selectedVideo ? selectedVideo.url : ''}
        />
      </div>
      <p className='text-center text-gray-100 mt-2 font-medium md:text-2xl '>{selectedVideo?.title}</p>
      {/* {
        modalVideo &&
        <VideoModal
        modalVideo={modalVideo}
        selectModalVideo={selectModalVideo}
        >
  
        </VideoModal>
      } */}
    </div>
  );
};

export default VideoPlayer;
