import React from 'react';
import { Document, Page, Text, Font, StyleSheet, pdf, View } from '@react-pdf/renderer';

const UpdatePricingNotice = () => {
  const createPDF = async () => {
    try {
      Font.register({ family: 'Source Sans Pro', src: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap' });

      const pdfContent = (
        <Document>
          <Page size="A4" style={styles.page}>
            {/* Header Section */}
            <View style={styles.header}>
              {/* Add a logo here if necessary */}
              <Text style={styles.heading}>Brooklyn Institute of Vocational Training, LLC</Text>
              <Text style={styles.address}>110-05 Liberty Avenue, Queens, New York 11419</Text>
              <Text style={styles.contact}>Contact: +1929-386-0092, email: <Text style={styles.email}>info@mybrooklyninstitute.com</Text></Text>
            </View>

            <View style={styles.divider} />

            <Text style={styles.subHeading}>Class Pricing & Requirements</Text>

            {/* Program Fee Section */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>PROGRAM FEE</Text>
              <View style={styles.feeDetails}>
                <Text style={styles.detailItem}>1. HOURS: <Text style={styles.value}>83</Text></Text>
                <Text style={styles.detailItem}>2. TUITION: <Text style={styles.value}>$499 (You can pay in installments)</Text></Text>
                <Text style={styles.detailItem}>3. TEXTBOOK: <Text style={styles.value}>$50</Text></Text>
                <Text style={styles.detailItem}>4. REGISTRATION (NON-REFUNDABLE): <Text style={styles.value}>$49</Text></Text>
                <Text style={styles.total}>5. TOTAL: <Text style={styles.value}>$598</Text></Text>
              </View>
            </View>

            <View style={styles.divider} />

            {/* Requirements Section */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>REQUIREMENTS</Text>
              <View style={styles.requirementsDetails}>
                <Text style={styles.bulletPoint}>1. A valid ID</Text>
                <Text style={styles.bulletPoint}>2. High School Diploma (OPTIONAL)</Text>
              </View>
            </View>
          </Page>
        </Document>
      );

      const blob = await pdf(pdfContent).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error creating PDF:', error);
    }
  };

  return (
    <div className='text-white'>
      <button className='px-3 py-2 rounded-md bg-primary font-semibold text-sm' onClick={createPDF}>View Notice</button>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
  },
  header: {
    textAlign: 'center',
    marginBottom: 20,
  },
  heading: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  address: {
    fontSize: 14,
    marginBottom: 5,
  },
  contact: {
    fontSize: 12,
  },
  email: {
    fontSize: 12,
    color: "blue",
  },
  divider: {
    borderBottom: '1 solid #000',
    marginBottom: 20,
  },
  subHeading: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 15,
    textDecoration: 'underline',
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    textDecoration: 'underline',
  },
  feeDetails: {
    marginBottom: 10,
    paddingLeft: 20,
  },
  detailItem: {
    fontSize: 14,
    marginBottom: 5,
  },
  total: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 5,
  },
  value: {
    fontWeight: 'normal',
  },
  requirementsDetails: {
    paddingLeft: 20,
  },
  bulletPoint: {
    fontSize: 14,
    marginBottom: 5,
  },
});

export default UpdatePricingNotice;
