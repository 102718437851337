import React, { useEffect, useState } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import './WeekEndGraduation.css'
import 'react-photo-view/dist/react-photo-view.css';
import img1 from '../../../src/Assets/weekend-graduation/img-1.jpg'
import img2 from '../../../src/Assets/weekend-graduation/img-2.jpg'
import img3 from '../../../src/Assets/weekend-graduation/img-3.jpg'
import img4 from '../../../src/Assets/weekend-graduation/img-4.jpg'
import img5 from '../../../src/Assets/weekend-graduation/img-5.jpg'
import img6 from '../../../src/Assets/weekend-graduation/img-6.jpg'
import img7 from '../../../src/Assets/weekend-graduation/img-7.jpg'
import img8 from '../../../src/Assets/weekend-graduation/img-8.jpg'
import img9 from '../../../src/Assets/weekend-graduation/img-9.jpg'
import img10 from '../../../src/Assets/weekend-graduation/img-10.jpg'
import img11 from '../../../src/Assets/weekend-graduation/img-11.jpg'
import img12 from '../../../src/Assets/weekend-graduation/img-12.jpg'
import img13 from '../../../src/Assets/weekend-graduation/img-13.jpg'
import img14 from '../../../src/Assets/weekend-graduation/img-14.jpg'
import img15 from '../../../src/Assets/weekend-graduation/img-15.jpg'
import img16 from '../../../src/Assets/weekend-graduation/img-16.jpg'
import img18 from '../../../src/Assets/weekend-graduation/img-18.jpg'
import img19 from '../../../src/Assets/weekend-graduation/img-19.jpg'
import img20 from '../../../src/Assets/weekend-graduation/img-20.jpg'
import { useLocation } from 'react-router-dom';
import useTitle from '../../Hooks/useTitle';
import { ClipLoader } from 'react-spinners';

const WeekEndGraduation = () => {
    
    const location = useLocation()

    useTitle(location.pathname.substring(1))

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleImageLoad = () => setLoading(false);
        const images = [
            img1, img2, img3, img4, img5, img6, img7, img8, img9, img10
        ];
        let loadedImages = 0;

        images?.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImages += 1;
                if (loadedImages === images.length) {
                    handleImageLoad();
                }
            };
        });
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen dark:bg-slate-600">
                <ClipLoader color="#3498db" loading={loading} size={150} className="loader-large" />
            </div>
        );
    }


    return (
        <div className='dark:bg-slate-600'>
            <div className="Weekend-banner  min-h-[80vh] relative">
                <h1 className='lg:pt-[50vh] pt-[40vh] text-center text-white  text-2xl md:text-5xl font-semibold'>

                    <span className='graduation-title px-4 py-2'>
                    Weekend Class Graduation - 2023
                    </span>

                </h1>

            </div>
            <div className="md:py-10 lg:w-[80%] w-[95%] mx-auto">
                <div className="lg:mb-8 lg:pt-12 py-5">
                    <h1 className="text-gray-700 text-center  text-xl  advertise md:text-4xl font-semibold dark:text-gray-100">
                        {" "}
                        Weekend Class Graduation
                    </h1>
                    <div className="flex justify-center ">
                        <hr className="bg-primary px-5 md:mt-1 mt-1 py-[1px] w-[100px]" />
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-7">
                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            <PhotoProvider>
                                <PhotoView src={img1}>
                                    <img
                                        src={img1}
                                        alt="img-1"
                                        className="w-full h-[100%]  center shadow-md border-[1px] border-primary  object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class=" row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <PhotoProvider>
                                <PhotoView src={img2}>
                                    <img
                                        src={img2}
                                        alt="img2"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                                        loading='lazy'
                                    ></img>
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1400"
                        >
                            <PhotoProvider>
                                <PhotoView src={img4}>
                                    <img
                                        src={img4}
                                        alt="img3"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-2 col-span-2 lg:h-[628px] h-[428px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            <PhotoProvider>
                                <PhotoView src={img3}>
                                    <img
                                        src={img3}
                                        alt=""
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                    </div>

                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            <PhotoProvider>
                                <PhotoView src={img5}>
                                    <img
                                        src={img5}
                                        alt="img-1"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover  custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                        >
                            <PhotoProvider>
                                <PhotoView src={img7}>
                                    <img
                                        src={img7}
                                        alt="img-1"
                                        className="w-full h-[100%] object-cover center shadow-md border-[1px] border-primary cursor-pointer  custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-up"
                            data-aos-duration="1400"
                        >
                            <PhotoProvider>
                                <PhotoView src={img9}>
                                    <img
                                        src={img9}
                                        alt="img-1"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover  custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe "
                            data-aos="fade-down"
                            data-aos-duration="1400"
                        >
                            <PhotoProvider>
                                <PhotoView src={img6}>
                                    <img
                                        src={img6}
                                        alt="img-1"
                                        className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2  lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer  "
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            <PhotoProvider>
                                <PhotoView src={img10}>
                                    <img
                                        src={img10}
                                        alt="img-1"
                                        className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-7 mt-[28px]">
                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            <PhotoProvider>
                                <PhotoView src={img11}>
                                    <img
                                        src={img11}
                                        alt="img-10"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class=" row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <PhotoProvider>
                                <PhotoView src={img12}>
                                    <img
                                        src={img12}
                                        alt="img11"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                                        loading='lazy'
                                    ></img>
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1400"
                        >
                            <PhotoProvider>
                                <PhotoView src={img13}>
                                    <img
                                        src={img13}
                                        alt="img12"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-2 col-span-2 lg:h-[628px] h-[428px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            <PhotoProvider>
                                <PhotoView src={img14}>
                                    <img
                                        src={img14}
                                        alt="img13"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                    </div>

                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            <PhotoProvider>
                                <PhotoView src={img15}>
                                    <img
                                        src={img15}
                                        alt="img14"
                                        className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                        >
                            <PhotoProvider>
                                <PhotoView src={img16}>
                                    <img
                                        src={img16}
                                        alt="img15"
                                        className="w-full h-[100%] object-cover center shadow-md border-[1px] border-primary cursor-pointer   custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-up"
                            data-aos-duration="1400"
                        >
                            <PhotoProvider>
                                <PhotoView src={img18}>
                                    <img
                                        src={img18}
                                        alt="img16"
                                        className="w-full h-[100%] object-cover center shadow-md border-[1px] border-primary cursor-pointer  custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe "
                            data-aos="fade-down"
                            data-aos-duration="1400"
                        >
                            <PhotoProvider>
                                <PhotoView src={img19}>
                                    <img
                                        src={img19}
                                        alt="img-1"
                                        className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-1 col-span-2  lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer  "
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            <PhotoProvider>
                                <PhotoView src={img20}>
                                    <img
                                        src={img20}
                                        alt="img18"
                                        className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                                        loading='lazy'
                                    />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default WeekEndGraduation
