import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialSlider.css'

const TestimonialSlider = () => {

    const allData = [

        {
            src: "https://www.youtube.com/embed/2_lQyQxU-kA?rel=0"

        },
        {
            src: "https://www.youtube.com/embed/zP9zmIqwH1Y?rel=0"

        },

        {
            src: "https://www.youtube.com/embed/ya9fI7iTTiY?rel=0"

        },

        {
            src: "https://www.youtube.com/embed/0O73OUAS3UI?rel=0"
        },

        {
            src: "https://www.youtube.com/embed/muxVDJ6dxDU?rel=0"

        }

    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        autoplaySpeed: 50,
        pauseOnHover: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1, 
                },
            },

        ],
    };

    return (
        <div className=" mb-4  md:my-8 ">
            <Slider {...settings}>


                {


                    allData?.map((snSl, index) =>

                        <div

                            key={index}
                            className="  "

                        >

                            <div className='bg-white dark:bg-[#1C3460]  rounded-xl shadow-xl h-[360px] md:h-[450px] carousel-card'
                                style={{ margin: '20px' }}
                            >

                                {
                                    snSl?.src &&

                                        <iframe
                                            className=" w-full h-full rounded-xl"
                                            id="youtubeIframe"
                                            src={snSl?.src}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen="allowfullscreen"
                                            mozallowfullscreen="mozallowfullscreen"
                                            msallowfullscreen="msallowfullscreen"
                                            oallowfullscreen="oallowfullscreen"
                                            webkitallowfullscreen="webkitallowfullscreen"
                                        />
                                    
                                    }

                            </div>

                        </div>)
                }


            </Slider>
        </div>
    );
};

export default TestimonialSlider;