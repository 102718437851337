import React, { useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoIosImages } from "react-icons/io";
import OverlayLoading from "../../Shared/OverlayLoading";
import toast from "react-hot-toast";

const ImageUploader = () => {
  const [images, setImages] = useState([]);
  const [albumName, setAlbumName] = useState("");
  const [cardCover, setCardCover] = useState(null); // State for card cover
  const [coverImage, setCoverImage] = useState(null); // State for cover image
  const [loading, setLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const newImages = acceptedFiles?.map((file) => ({
      id: file.name,
      file: file,
      preview: URL.createObjectURL(file),
    }));
    setImages([...images, ...newImages]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  const handleFileChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      setImage({
        file: file,
        preview: URL.createObjectURL(file),
      });
    }
  };

  const handleUpload = async () => {
    if (!albumName || !cardCover || !coverImage) {
      alert("Please enter an album name and select card cover and cover image before uploading.");
      return;
    }

    setLoading(true);

    const uploadImage = async (image) => {
      const formData = new FormData();
      formData.append("file", image.file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );
      formData.append("folder", albumName);

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData
        );
        return response.data.secure_url;
      } catch (error) {
        console.error("Upload failed:", error);
        return null;
      }
    };

    const uploadPromises = images?.map((image) => uploadImage(image));

    const uploadedImageUrls = await Promise.all(uploadPromises);
    const successfulUploads = uploadedImageUrls.filter((url) => url !== null);

    // Upload card cover and cover image
    const cardCoverUrl = await uploadImage(cardCover);
    const coverImageUrl = await uploadImage(coverImage);

    if (cardCoverUrl && coverImageUrl && successfulUploads.length > 0) {
      try {
        const res = await axios.put("https://brooklyn-server-v4.vercel.app/albums/update", {
          images: successfulUploads,
          albumName: albumName,
          cardCover: cardCoverUrl, // Save card cover URL to MongoDB
          coverImage: coverImageUrl, // Save cover image URL to MongoDB
        });

        toast.success("Images uploaded Successfully");
        setLoading(false);
      } catch (err) {
        console.error("Error saving to MongoDB:", err);
        toast.error("Having issue with Image upload");
        setLoading(false);
      }
    } else {
      toast.error("Failed to upload some images");
      setLoading(false);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);

    setImages(reorderedImages);
  };

  const renderImageGrid = () => {
    const chunks = chunkArray(images, 9);

    return chunks.map((chunk, chunkIndex) => (
      <div
        key={chunkIndex}
        className="grid md:grid-cols-2 gap-7 md:py-10 lg:w-[80%] w-[95%] mx-auto"
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`column-1-${chunkIndex}`}>
            {(provided) => (
              <div
                className="grid grid-rows-3 grid-flow-col gap-7"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {chunk.slice(0, 4).map((image, index) => (
                  <Draggable
                    key={image.id}
                    draggableId={`${image.id}-${chunkIndex}-${index}`} // Ensure unique ID for each image
                    index={chunkIndex * 9 + index} // Ensure unique index across all chunks
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${
                          index === 0
                            ? "row-span-1 col-span-4 lg:h-[300px] h-[200px]"
                            : index === 3
                            ? "row-span-2 col-span-2 lg:h-[628px] h-[428px]"
                            : "row-span-1 col-span-2 lg:h-[300px] h-[200px]"
                        } overflow-hidden w-full cursor-pointer relative`}
                      >
                        <PhotoProvider>
                          <PhotoView src={image.preview}>
                            <img
                              src={image.preview}
                              alt={`img-${index + 1}`}
                              className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover"
                              loading="lazy"
                            />
                          </PhotoView>
                        </PhotoProvider>
                        <button
                          className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 z-10"
                          onClick={() => handleRemoveImage(image.id)}
                        >
                          &minus;
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Droppable droppableId={`column-2-${chunkIndex}`}>
            {(provided) => (
              <div
                className="grid grid-rows-3 grid-flow-col gap-7"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {chunk.slice(4).map((image, index) => (
                  <Draggable
                    key={image.id}
                    draggableId={`${image.id}-${chunkIndex}-${index + 4}`} // Ensure unique ID for each image
                    index={chunkIndex * 9 + index + 4} // Ensure unique index across all chunks
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${
                          index === 0
                            ? "row-span-1 col-span-2 lg:h-[300px] h-[200px]"
                            : index === 1
                            ? "row-span-1 col-span-4 lg:h-[300px] h-[200px]"
                            : "row-span-1 col-span-2 lg:h-[300px] h-[200px]"
                        } overflow-hidden w-full cursor-pointer relative`}
                      >
                        <PhotoProvider>
                          <PhotoView src={image.preview}>
                            <img
                              src={image.preview}
                              alt={`img-${index + 5}`}
                              className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover"
                              loading="lazy"
                            />
                          </PhotoView>
                        </PhotoProvider>
                        <button
                          className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 z-10"
                          onClick={() => handleRemoveImage(image.id)}
                        >
                          &minus;
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    ));
  };

  return (
    <div className="image-uploader py-5 min-h-screen border-[1px] border-red-600">
      {loading && <OverlayLoading />}
      <div className="w-[95%] md:w-[400px] mx-auto items-center dark:text-gray-100">
        <div className="album-name-input my-4">
          <input
            type="text"
            value={albumName}
            onChange={(e) => setAlbumName(e.target.value)}
            placeholder="Enter Album Name"
            className="border-[1px] border-primary w-[400px] rounded-md px-2 py-1 dark:bg-slate-600 "
          />
        </div>

        <div className="my-4">
          <input
            type="file"
            onChange={(e) => handleFileChange(e, setCardCover)}
            className="border-[1px] border-primary rounded-md px-2 py-1  w-[400px] "
          />
          <p className="text-sm text-gray-500 mt-2 dark:text-gray-100">Select Card Cover Image</p>
        </div>

        <div className="my-4">
          <input
            type="file"
            onChange={(e) => handleFileChange(e, setCoverImage)}
            className="border-[1px] border-primary rounded-md px-2 py-1 w-[400px] "
          />
          <p className="text-sm text-gray-500 mt-2 dark:text-gray-100">Select Cover Image</p>
        </div>

        <div {...getRootProps()} className="dropzone cursor-pointer">
          <input {...getInputProps()} />
          <div className="flex items-center gap-2 border-[1px] border-primary rounded-md px-2 py-1 justify-center ">
            <p>Select Your Pictures</p> <IoIosImages className="text-3xl" />
          </div>
        </div>

        <div className="flex justify-center my-5">
          <button
            className="upload-button mt-5 px-5 py-2 bg-blue-600 text-white rounded"
            onClick={handleUpload}
            disabled={loading}
          >
            Upload Images
          </button>
        </div>
      </div>

      {renderImageGrid()}
    </div>
  );
};

export default ImageUploader;
