import React, { useState } from 'react';
import VideoCard from './VideoCard';

const VideoList = ({ videos ,onVideoSelect}) => {
  const videosPerPage = 3; // Number of videos to display per page
  const [currentPage, setCurrentPage] = useState(0);

  const startIndex = currentPage * videosPerPage;
  const endIndex = startIndex + videosPerPage;
  const videosToDisplay = videos.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className="">
        {videosToDisplay?.map((video) => (
          <VideoCard key={video.id} video={video} onVideoSelect={onVideoSelect} />
        ))}
      </div>
      <div className="flex justify-start mt-4">
        {Array.from({ length: Math.ceil(videos.length / videosPerPage) }, (_, index) => (
          <button
            key={index}
            className={` py-2 px-4 border-[1px]  ${
              currentPage === index ? 'bg-blue-500 text-gray-100' : 'bg-gray-400 text-gray-100'
            }`}
            onClick={() => handlePageChange(index)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default VideoList;
