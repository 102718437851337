import React, { useRef, useState } from 'react';

const VideoCard = ({ video, onVideoSelect }) => {

  // const videoRef = useRef(null);

  // const handleMouseEnter = () => {
  //   videoRef.current.play();
  // };

  // const handleMouseLeave = () => {
  //   videoRef.current.pause();
  //   videoRef.current.currentTime = 0;
  // };

  const handleClick = () => {
    onVideoSelect(video);
  };

  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <div
      className=" w-full  h-40 cursor-pointer overflow-hidden mx-auto md:mx-0 mt-5 md:mt-0 mb-4 rounded-md border-2 border-primary dark:border-gray-100 shadow-md "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className=' grid grid-cols-2 gap-2'>
        {
          video?.width === true ?
            <>

              {
                video?.crisToper === true ?
                  <>
                    <div className="h-full relative">
                      {/* Display a custom thumbnail image */}
                      <img
                        src={video?.img} // Replace 'customThumbnailUrl' with your custom thumbnail image URL
                        alt="Video Thumbnail"
                        className={`object-cover rounded-md h-40 w-full ${isHovered ? 'hidden' : 'block'}`}
                      />
                      {/* Display the video element on hover */}
                      <video
                        ref={videoRef}
                        muted
                        src={video.url}
                        className={`object-cover rounded-md h-64 w-full ${isHovered ? 'block' : 'hidden'}`}
                      />
                    </div>

                  </>
                  :
                  <>
                    {/* <div className="h-full ">
                      <video
                        ref={videoRef}
                        muted
                        src={video.url}
                        className="object-cover rounded-md h-64 "
                      />

                    </div> */}

                    <div className="h-full relative">
                      {/* Display a custom thumbnail image */}
                      <img
                        src={video?.img} // Replace 'customThumbnailUrl' with your custom thumbnail image URL
                        alt="Video Thumbnail"
                        className={`object-cover rounded-md h-40 w-full ${isHovered ? 'hidden' : 'block'}`}
                      />
                      {/* Display the video element on hover */}
                      <video
                        ref={videoRef}
                        muted
                        src={video.url}
                        className={`object-cover rounded-md h-64 w-full ${isHovered ? 'block' : 'hidden'}`}
                      />
                    </div>

                  </>
              }
            </>
            :
            <>

              {

                video?.width === 'savita' ?
                  <>


                    <div className="h-full relative">
                      {/* Display a custom thumbnail image */}
                      <img
                        src={video?.img} // Replace 'customThumbnailUrl' with your custom thumbnail image URL
                        alt="Video Thumbnail"
                        className={`object-cover rounded-md h-60 w-full ${isHovered ? 'hidden' : 'block'}`}
                      />
                      {/* Display the video element on hover */}
                      <video
                        ref={videoRef}
                        muted
                        src={video.url}
                        className={`object-cover rounded-md h-96 w-full ${isHovered ? 'block' : 'hidden'}`}
                      />
                    </div>

                  </>
                  :
                  <>
                    {
                      video?.width === "grandma" ?
                        <>
                          <div className="h-full relative">
                            {/* Display a custom thumbnail image */}
                            <img
                              src={video?.img} // Replace 'customThumbnailUrl' with your custom thumbnail image URL
                              alt="Video Thumbnail"
                              className={`object-cover rounded-md h-60 w-full ${isHovered ? 'hidden' : 'block'}`}
                            />
                            {/* Display the video element on hover */}
                            <video
                              ref={videoRef}
                              muted
                              src={video.url}
                              className={`object-cover rounded-md h-80 w-full ${isHovered ? 'block' : 'hidden'}`}
                            />
                          </div>

                        </>
                        :
                        <>
                          {/* <div className="h-full ">
                            <video
                              ref={videoRef}
                              muted
                              src={video.url}
                              className="object-cover rounded-md h-56 "
                            />
                          </div> */}

                          <div className="h-full relative">
                            {/* Display a custom thumbnail image */}
                            <img
                              src={video?.img} // Replace 'customThumbnailUrl' with your custom thumbnail image URL
                              alt="Video Thumbnail"
                              className={`object-cover rounded-md h-60 w-full ${isHovered ? 'hidden' : 'block'}`}
                            />
                            {/* Display the video element on hover */}
                            <video
                              ref={videoRef}
                              muted
                              src={video.url}
                              className={`object-cover rounded-md h-56 w-full ${isHovered ? 'block' : 'hidden'}`}
                            />
                          </div>


                        </>
                    }

                  </>
              }

            </>
        }

        <div>
          <p className=' p-2 font-semibold dark:text-gray-100 h-40 flex justify-center items-center'>{video?.title}</p>
        </div>


      </div>

    </div>
  );
};

export default VideoCard;
