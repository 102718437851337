export const updateNavigationHistory = (pathname) => {
  // Get the current navigation history from sessionStorage or set an empty array
  let navigationHistory = JSON.parse(sessionStorage?.getItem('navigationHistory')) || [];

  // Add new path only if it's not already in the history or not the last one
  if (navigationHistory.length === 0 || navigationHistory[navigationHistory.length - 1] !== pathname) {
    // Push the new path
    navigationHistory.push(pathname);

    // Ensure no more than 5 items in the history, remove the oldest entry if necessary
    if (navigationHistory.length > 5) {
      navigationHistory = navigationHistory.slice(-5); // Keep the last 5 entries
    }

    // Update the sessionStorage with the new navigation history
    sessionStorage.setItem('navigationHistory', JSON.stringify(navigationHistory));
  }
};
