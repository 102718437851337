import React, { useEffect } from 'react';
import './translate.css';

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    if (window.google && window.google.translate) {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
        },
        'google_translate_element'
      );
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Assign the init function to the global window object
    window.googleTranslateElementInit = googleTranslateElementInit;

    // Cleanup script and global function on unmount
    return () => {
      document.body.removeChild(script);
      delete window.googleTranslateElementInit;
    };
  }, []);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
