// ScrollContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const ScrollContext = createContext();

export const useScroll = () => {
  return useContext(ScrollContext);
};

export const ScrollProvider = ({ children }) => {
  const [scrollBehavior, setScrollBehavior] = useState('auto');

  const updateScrollBehavior = (behavior) => {
    document.documentElement.style.scrollBehavior = behavior;
    setScrollBehavior(behavior);
  };

  const resetScrollBehavior = () => {
    updateScrollBehavior('auto');
  };

  return (
    <ScrollContext.Provider value={{ scrollBehavior, updateScrollBehavior, resetScrollBehavior }}>
      {children}
    </ScrollContext.Provider>
  );
};
