import React from 'react';
import license from '../../../src/Assets/BPSS_Logo-300x233.png';

import { GiPlainSquare } from 'react-icons/gi';



const Licence = () => {
    return (
        <div className='pt-12 pb-16 bg-[#e8f3f8]  dark:bg-slate-600' >
            <div className='w-[95%] md:w-[90%]  license-section mx-auto rounded-md border-[1.5px] shadow-sm text-gray-700 px-8 py-8 md:px-8 md:py-16 bg-white dark:bg-slate-800 dark:border-gray-200'>
            <div className=''>
           
            <div>
                <h1 className=' text-[#A13744] md:text-5xl  font-bold playrify text-center text-xl dark:text-gray-100'>
                Licensed by the State of New York
                
                </h1>
                <div className='flex justify-center mt-2'>
            <hr className='w-[15%] border-t-[2px] border-gray-500 dark:border-gray-100' />
            </div>
                <div  className='flex justify-center mt-10'>
                <img src={license} alt="" className='w-[200px]' />
            </div>
                <div className='  mt-10  license-font text-base hidden md:block'>
                
                    <p className=' text-justify mt-2 flex items-center gap-3 dark:text-gray-100'> <GiPlainSquare className='w-3 h-w-3 text-[#A13744] dark:text-gray-100' /> The Brooklyn Institute of Vocational Training provides personalized attention and support to each student.</p>
                    <p className=' text-justify mt-2 flex items-center gap-3 dark:text-gray-100'> <GiPlainSquare className='w-3 h-3  text-[#A13744] dark:text-gray-100' /> Our school offers a comprehensive curriculum that covers all aspects of home health care, preparing graduates to enter the workforce as competent and qualified HHAs.</p>
                    <p className=' text-justify mt-2 flex items-center gap-3 dark:text-gray-100'> <GiPlainSquare className='w-[22px] h-[22px] text-[#A13744] dark:text-gray-100' /> The Brooklyn Institute of Vocational Training is an excellent choice for anyone who wants to pursue a career as an HHA. With our experienced faculty, comprehensive curriculum, and commitment to diversity and inclusiveness, the school is well-positioned to prepare students for success in this challenging and rewarding field.</p>
                    <p className=' text-justify mt-2 flex items-center gap-3 dark:text-gray-100'> <GiPlainSquare className='w-3 h-3   text-[#A13744] dark:text-gray-100' /> We're conveniently located on Liberty Avenue, easily accessible by bus and train. </p>

                
                </div>

            </div>
            </div>



            </div>

            


        </div>
    );
};

export default Licence;