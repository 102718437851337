import React from "react";
import "./Clock.css";

const Clock = ({
  timerDays,
  timerHours,
  timerMinutes,
  timerSeconds,
  countDown,
}) => {

  // console.log(new Date(`${countDown?.[0]?.date }`).getTime() <= new Date().getTime());



  return (
    <div className="roboto mt-5">
      <div className="lg:hidden  mt-10 ">
        <div>
          <div className="flex justify-center ">
            <div>
              <h1 className=" text-2xl md:text-5xl playrify font-semibold mb-5  mt-5 md:pt-14 text-center dark:text-gray-100">
                Upcoming classes
              </h1>
              <h1 className="text-2xl md:text-5xl playrify font-semibold mb-10 text-center dark:text-gray-100">
                {countDown?.[0]?.classTitle}
                <span className="ml-1">{countDown?.[0]?.date} </span>{" "}
              </h1>
            </div>
          </div>
          {new Date(`${countDown?.[0]?.date }`).getTime() <= new Date().getTime() ? (
            <>
              <div className="clock flex justify-center gap-4 md:gap-8 items-center bg-[#CA4757] text-white lg:[70%] md:w-[80%] w-[95%] mx-auto rounded-full px-8 py-5 ">
               
               <p className="text-xl md:text-5xl font-semibold">The Class Has Been Started.</p>

              </div>
            </>
          ) : (
            <>
              <div className="clock flex justify-center gap-4 md:gap-8 items-center bg-[#CA4757] text-white lg:[70%] md:w-[80%] w-[95%] mx-auto rounded-full px-8 py-5 ">
                <div className="text-center leading-relaxed ">
                  <p className="text-xl md:text-5xl font-semibold">
                    {timerDays}
                  </p>
                  {/* timerDays */}
                  <p className="md:text-xl mt-2">Days</p>
                </div>
                <p className=" py-10 px-[1px] bg-white "></p>
                <div className="text-center   ">
                  <p className="text-xl md:text-5xl font-semibold">
                    {timerHours}
                  </p>
                  {/* timerHours */}
                  <p className="md:text-xl mt-2">Hours</p>
                </div>{" "}
                <p className=" py-10 px-[1px] bg-white "></p>
                <div className="text-center  ">
                  <p className="text-xl md:text-5xl font-semibold">
                    {timerMinutes}
                  </p>
                  {/* timerMinutes */}
                  <p className="text-center md:text-xl mt-2">Minutes</p>
                </div>{" "}
                <p className=" py-10 px-[1px] bg-white "></p>
                <div className="text-center ">
                  <p className="text-xl md:text-5xl font-semibold">
                    {timerSeconds}
                  </p>
                  {/* timerSeconds */}
                  <p className="text-center md:text-xl mt-2">Seconds</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className=" clock-section  lg:w-[1100px] mx-auto text-white hidden lg:block  mt-20">
        <section className="timer-container ">
          <section className="relative">
            <div className="absolute bg-white admission h-[340px] w-[330px] rounded-full -left-6 -top-2 shadow-2xl border-[40px] border-[#CA4757]">
              <div className="text-gray-800 font-medium flex justify-center mt-[11vh]  playrify md:text-4xl">
                <h1>
                  Admission
                  <p className="mt-2">On Going</p>
                </h1>
              </div>
            </div>

            <div className="  countDown-bg h-[360px] ml-[12%]">
              <div className="flex justify-center ml-[8%]">
                <div>
                  <h1 className=" text-3xl playrify font-semibold mb-5 pt-10 ">
                    Upcoming classes
                  </h1>
                  <h1 className=" text-3xl playrify font-semibold mb-10">
                    {" "}
                    {countDown?.[0]?.classTitle}
                    <span className="ml-10">{countDown?.[0]?.date}</span>{" "}
                  </h1>
                </div>
              </div>
              {
                new Date(`${countDown?.[0]?.date }`).getTime() <= new Date().getTime() ?
                <>
                   <p className=" md:text-4xl font-semibold text-center ml-[8%]">The Class Has Been Started</p>
                </>
                :
                <>
                <div className="clock flex justify-center gap-8 items-center ">
                <div className="text-center leading-relaxed ">
                  <p className=" md:text-4xl font-semibold">{timerDays}</p>
                  {/* timerDays */}
                  <p className="text-xl mt-3">Days</p>
                </div>
                <p className=" py-10 px-[1px] bg-white "></p>
                <div className="text-center   ">
                  <p className="md:text-4xl font-semibold">{timerHours}</p>
                  {/* timerHours */}
                  <p className="text-xl mt-3">Hours</p>
                </div>{" "}
                <p className=" py-10 px-[1px] bg-white "></p>
                <div className="text-center  ">
                  <p className="md:text-4xl font-semibold">{timerMinutes}</p>
                  {/* timerMinutes */}
                  <p className="text-center text-xl mt-3">Minutes</p>
                </div>{" "}
                <p className=" py-10 px-[1px] bg-white "></p>
                <div className="text-center text-xl">
                  <p className="md:text-4xl font-semibold">{timerSeconds}</p>
                  {/* timerSeconds */}
                  <p className="text-center text-xl mt-3">Seconds</p>
                </div>
              </div>
                
                </>
              }
              
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

Clock.defaultProps = {
  timerDays: 10,
  timerHours: 10,
  timerMinutes: 10,
  timerSeconds: 10,
};

export default Clock;
