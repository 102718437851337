import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import './ClassSlider.css'

import nourse1 from '../../../src/Assets/HHH_Certification/img1.webp';
import nourse2 from '../../../src/Assets/HHH_Certification/img2.webp';
import nourse3 from '../../../src/Assets/HHH_Certification/img3.webp';

import { Link } from "react-router-dom";

import { SliderButton } from "../../Shared/SliderButton";
export default function Slider() {
    return (
        <>
            <Swiper
                pagination={ true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper relative"
            >
                <SwiperSlide>
                    <div
                        className="lg:h-[550px] w-full h-[200px] md:h-[300px] class-carousel  bg-cover bg-center bg-no-repeat text-white rounded-xl "
                        style={{
                            backgroundImage: `url(${nourse1})`,
                        }}>
                            
                        <div className="carousel-card h-full w-full lg:pt-32 p-5 rounded-xl ">
                           <div className="flex justify-end ">
                           <div className=" md:w-[50%] md:pl-5 ">
                           <h1 className="lg:text-5xl text-lg roboto font-semibold">
                           Brooklyn Institute of 
                                   <p className="md:mt-2 mt-0">
                                   Vocational Training
                                   </p>
                                </h1>
                                <p className="md:mt-3 mt-0 text-sm md:text-2xl hidden md:block ">

                                Become A Certified HHA Today!।
                                </p>
                                <p className="md:mt-3 mt-0 text-sm md:text-2xl md:hidden ">

                                Become A Certified HHA <br /> Today!।
                                </p>
                               <div className="md:mt-5 mt-3">
                               <Link to='/contact' >
                                    <button className="bg-gradient-to-r from-[#f71f1f7a] to-[#ff989877] px-3 py-2 md:px-4 md:py-3 rounded-md font-semibold shadow-lg uppercase md:text-base text-xs">
                                        Contact Us
                                    </button>
                                </Link>
                               </div>
                           </div>
                           </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="lg:h-[550px] h-[200px] md:h-[300px]  class-carousel bg-cover bg-center bg-no-repeat text-white rounded-xl "
                        style={{
                            backgroundImage: `url(${nourse2})`,
                        }}
                    >
                        <div className=" h-full w-full lg:pt-32 p-5 lg:pl-20 rounded-xl"  style={{
                                backgroundImage: "linear-gradient(to right, #F24F57 10%, transparent 70%)"
                            }}>
                            <div>
                                
                            <h1 className="lg:text-5xl text-lg roboto font-semibold">
                           Brooklyn Institute of 
                                   <p className="md:mt-2 mt-0">
                                   Vocational Training
                                   </p>
                                </h1>
                                <p className="md:mt-3 mt-0 text-sm md:text-2xl hidden md:block ">

                                Become A Certified HHA Today!।
                                </p>
                                <p className="md:mt-3 mt-0 text-sm md:text-2xl md:hidden ">

                                Become A Certified HHA <br /> Today!।
                                </p>
                               <div className="mt-5">
                               <Link to='/contact' >
                                    <button className="bg-gradient-to-r from-[#f71f1f7a] to-[#ff989877] px-3 py-2 md:px-4 md:py-3 rounded-md font-semibold shadow-lg uppercase md:text-base text-xs">
                                        Contact Us
                                    </button>
                                </Link>
                               </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="lg:h-[550px] h-[200px] md:h-[300px]  class-carousel  bg-cover bg-center bg-no-repeat text-white rounded-xl"
                        style={{
                            backgroundImage: `url(${nourse3})`,
                        }}

                    >
                        <div className="carousel-card h-full w-full lg:pt-32 p-5 lg:pl-18 rounded-xl">
                        <div className="flex justify-end">
                        <div className=" md:w-[50%] pl-5 ">
                        <h1 className="lg:text-5xl text-lg roboto font-semibold">
                           Brooklyn Institute of 
                                   <p className="md:mt-2 mt-0">
                                   Vocational Training
                                   </p>
                                </h1>
                                <p className="md:mt-3 mt-0 text-sm md:text-2xl hidden md:block ">

                                Become A Certified HHA Today!।
                                </p>
                                <p className="md:mt-3 mt-0 text-sm md:text-2xl md:hidden ">

                                Become A Certified HHA <br /> Today!।
                                </p>
                               <div className="md:mt-5 mt-3">
                               <Link to='/contact' >
                                    <button className=" bg-gradient-to-r from-[#f71f1f7a] to-[#ff989877] px-3 py-2 md:px-4 md:py-3 rounded-md font-semibold shadow-lg uppercase md:text-base text-xs">
                                        Contact Us
                                    </button>
                                </Link>
                               </div>
                           </div>
                           </div>
                        </div>
                    </div>
                    
                </SwiperSlide>

                <div className="absolute bottom-6 right-14 z-30 hidden md:block">
                    <SliderButton></SliderButton>
                </div>
               
            </Swiper>
        </>
    );
}