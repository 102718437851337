import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import { IoLocationSharp } from 'react-icons/io5';

import './ContactCard.css';

const ContactCard = () => {



    const opening = {
        icon: <FiClock />,
        title: "Opening Hours",
        description1: 'Sat - Sun : 08:30AM - 05:00PM',
        description: 'Mon - Fri : 08:30AM - 08:00PM'

    }
    const address = {
        icon: <IoLocationSharp />,
        title: "Address",
        link: '110-05 Liberty Avenue, Queens, NY-11419, USA',
        address: "https://www.google.com/maps/dir//110-05+Liberty+Ave,+Queens,+NY+11419/@40.6847005,-73.8317289,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c26750729d3653:0x592e70afeb742348!2m2!1d-73.8317289!2d40.6847005?hl=en",


    }

    const phone = {
        icon: <BsFillTelephoneFill />,
        title: "Phone",
        link1: '+1 (929) 386 0092',
        address1: 'tel:+1929-386-0092'


    }


    return (
        <div className='bg-[#e8f3f8] dark:bg-slate-600'>
            <div className='w-[95%] md:w-[90%] service-card lg:w-[80%] mx-auto  py-10 relative '>
            <div className='grid md:grid-cols-3 gap-7  '
                data-aos="fade-up"
                data-aos-duration="1000"
            >

                <div


                        className='text-center bg-cover shadow-lg rounded-md hover-effect2 border-[2px] border-[#CA4757]'


                >
                    <div className='my-10 px-4 lg:px-2'>
                            <p className='flex justify-center  '> <span className='w-11 h-11 bg-[#CA4757] flex justify-center items-center text-white rounded-full  text-2xl'>{opening?.icon}</span> </p>

                        <p className='mt-5 text-2xl playrify font-semibold'>
                            {opening?.title}
                        </p>

                        <p className='mt-5 Poppins text-[#5E5E5E] text-base'>
                            {
                                opening?.description
                            }
                        </p>
                            <a href={opening?.address} target='_blank' className='mt-5 Poppins text-[#5E5E5E] text-base hover:text-[#CA4757]' rel="noreferrer">
                            {
                                opening?.link
                            }
                        </a>
                        {
                            opening?.address1 &&
                            <a href={opening?.address1} className='mt-5 Poppins text-[#5E5E5E] text-base hover:text-[#CA4757]'>
                                {
                                    opening?.link1
                                }
                            </a>
                        }
                        <p className='mt-2 Poppins text-[#5E5E5E] text-base'>
                            {
                                opening?.description1
                            }
                        </p>
                    </div>



                </div>
                <a href={address?.address} target='_blank'


                    className='text-center bg-cover shadow-lg rounded-md hover-effect2 block border-[2px] border-[#CA4757] dark:bg-slate-800' rel="noreferrer"


                >
                    <div className='my-10 px-4 lg:px-2'>
                        <p className='flex justify-center  '> <span className='w-11 h-11 bg-[#CA4757] flex justify-center items-center text-white rounded-full  text-2xl'>{address?.icon}</span> </p>

                        <p className='mt-5 text-2xl playrify font-semibold'>
                            {address?.title}
                        </p>

                        <p className='mt-5 Poppins text-[#5E5E5E] text-base hover:text-[#CA4757]'>
                            {
                                address?.link
                            }
                        </p>
                       
                       
                        
                    </div>



                </a>
                <a href={phone?.address1}


                    className='text-center bg-cover shadow-lg rounded-md hover-effect2 border-[2px] border-[#CA4757] border-[1px] border-[#CA4757] block'


                >
                    <div className='my-10 px-4 lg:px-2 '>
                        <p className='flex justify-center  '> <span className='w-11 h-11 bg-[#CA4757] flex justify-center items-center text-white rounded-full  text-2xl'>{phone?.icon}</span> </p>

                        <p className='mt-5 text-2xl playrify font-semibold'>
                            {phone?.title}
                        </p>

                        <p className='mt-5 Poppins text-[#5E5E5E] text-base'>
                            {
                                phone?.description
                            }
                        </p>
                        <a href={phone?.address} target='_blank' className='mt-5 Poppins text-[#5E5E5E] text-base hover:text-[#CA4757]' rel="noreferrer">
                            {
                                opening?.link
                            }
                        </a>
                        {
                            phone?.address1 &&
                            <a href={phone?.address1} className='mt-5 Poppins text-[#5E5E5E] text-base hover:text-[#CA4757]'>
                                {
                                   phone?.link1
                                }
                            </a>
                        }
                        <p className='mt-2 Poppins text-[#5E5E5E] text-base'>
                            {
                                phone?.description1
                            }
                        </p>
                    </div>



                </a>
                


            </div>
            

        </div>
        </div>
    );
};

export default ContactCard;