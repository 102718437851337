import React, { useContext, useState } from "react";

import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { FaPhone, FaPhoneAlt, FaTiktok } from "react-icons/fa";
import { MdEmail, MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import "./Contact.css";
import Chat from "../../Shared/Chat/Chat";
import Marquee from "../../Shared/Marquee/Marquee";
import PageComponent from "../../PageComponent/PageComponent";

const Contact = () => {
  // window.scrollTo(0, 0);

  const now = new Date().toLocaleDateString("en-US", { weekday: "long" });

  const { user } = useContext(AuthContext);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  //   console.log(now);

  const apiKey = process.env.REACT_APP_secureApiKey;
 

  let val;
  let day;
  let text;

  switch (now) {
    case "Sunday":
      //   console.log("sun hello");
      val = "Sun";
      text = "Open today 08:00 am – 05:00 pm";
      day = "Sun 08:00 am – 05:00 pm";
      break;
    case "Monday":
      //   console.log("mon hello");
      val = "Mon";
      text = "Open today 08:30 am – 08:00 pm";
      day = "Mon 08:30 am – 08:00 pm";
      break;
    case "Tuesday":
      //   console.log("tue hello");
      val = "Tue";
      text = "Open today 08:30 am – 08:00 pm";
      day = "Tue 08:30 am – 08:00 pm";
      break;
    case "Wednesday":
      //   console.log("wed hello");
      val = "Wed";
      text = "Open today 08:30 am – 08:00 pm";
      day = "Wed 08:30 am – 08:00 pm";
      break;
    case "Thursday":
      //   console.log("thu hello");
      val = "Thu";
      text = "Open today 08:30 am – 08:00 pm";
      day = "Thu 08:30 am – 08:00 pm";
      break;
    case "Friday":
      //   console.log("fri hello");
      val = "Fri";
      text = "Open today 08:30 am – 08:00 pm";
      day = "Fri 08:30 am – 08:00 pm";
      break;
    case "queensSat":
      //   console.log("fri hello");
      val = "queensSat";
      text = "Open today 08:30 am – 05:00 pm";
      day = "Sat 08:30 am – 05:00 pm";
      break;
    case "queensSun":
      //   console.log("fri hello");
      val = "queensSun";
      text = "Open today 08:30 am – 05:00 pm";
      day = "Sun 08:30 am – 05:00 pm";
      break;
    default:
      val = "Sat";
      text = "Open today 08:00 am – 05:00 pm";
      day = "Sat 08:00 am – 05:00 pm";
  }

  const selectedText = { text };
  const selectedDay = { day };
  const selectedVal = { val };
  const all = [{ selectedText, selectedDay, selectedVal }];

  //function start

  const date = Date.now();
  const newDate = Date.now();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const emailStorage = (email, name) => {
    localStorage.setItem("email", `${email}`);
    localStorage.setItem("name", `${name}`);
    localStorage.setItem("chatLength", 1);
  };


  
  const smsHandler = (contactInfo) => {
    fetch("https://brooklyn-server-v4.vercel.app/sms", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        'X-API-Key': `${apiKey}`,
      },
      body: JSON.stringify(contactInfo),
    })
      .then((res) => res.json())
      .then((data) => {
        // toast.success(data?.message)
      });
  };


  const updateTime = (userMessage, email, name) => {
    const info = {
      date,
      email: email,
      message: userMessage,
      chat: "active",
      name: name,
    };

    fetch(`https://brooklyn-server-v4.vercel.app/users/time`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(info),
    })
      .then((res) => res.json())
      .then((data) => {
        //    console.log(data)
        if (data.acknowledged) {
          toast.success("message send successfully");
        }
      });
  };

  const saveUser = (
    name,
    email,
    verify = "false",
    chat = "active",
    time = newDate
  ) => {
    const newUser = { name, email, verify, chat, time };
    fetch("https://brooklyn-server-v4.vercel.app/users", {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(newUser),
    })
      .then((res) => res.json())
      .then((data) => {
        //    console.log(data)
        if (data.acknowledged) {
          emailStorage(email, name);
          reset();
        }
      });
  };

  const submitHandler = (data) => {
    const name = data.name;
    const email = data.email;
    const userMessage = data?.message;
    const contactInfo = {
      contact: data.phoneNumber
    };

    const message = {
      firstName: data.name,
      email: data.email,
      messages: data.message,
      contact: data.phoneNumber,
      time: date,
    };

    fetch("https://brooklyn-server-v4.vercel.app/chats/modifyChat", {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          // toast.success("Message Send Successfully");
          saveUser(name, email);
          smsHandler(contactInfo)
          updateTime(userMessage, email, name);
          setIsChecked(false)
        }
      });
  };
  return (
    <div className=" bg-[#E8F3F8] dark:bg-slate-600">
      
      <PageComponent
        title="Contact Us - Brooklyn Institute Of Vocational Training"
        description="Get in touch with the Brooklyn Institute Of Vocational Training for any inquiries or support related to our courses and services. Contact us via phone, email, or visit our campus to speak directly with our dedicated staff. We are here to assist you with your educational needs and ensure you have all the information you need."
        keywords="contact Brooklyn Institute, BI vocational training contact, educational support Brooklyn, phone contact BI, email Brooklyn Institute, visit Brooklyn Institute, vocational training support, educational inquiries Brooklyn, student support BI, Brooklyn Institute campus visit"
      />

      <div className="another-banner min-h-[80vh]">
        <div className="pt-[30vh]">
          <h1 className="text-[35px]  text-center font-bold relative advertise text-white afterEffect">
            Contact Us
          </h1>
          <div className="pt-11 pb-[120px] font-[Roboto] flex justify-center items-center gap-8 md:gap-0 md:space-y-0 md:space-x-7 flex-wrap">
            <a
              href="tel:+1929-386-0092"
              className="flex items-center px-6 py-3 md:px-16 md:py-6 border border-primary rounded-full text-white hover:bg-primary duration-500 bg-hov2"
            >
              <FaPhone className="mr-2" />
              Call Us
            </a>
            <Link
              to="/classes"
              className="flex items-center px-6 py-3 md:px-16 md:py-6 border border-primary rounded-full text-white hover:bg-primary duration-500 bg-hov2"
            >
              All Classes
            </Link>
          </div>
        </div>
      </div>
      <Marquee></Marquee>
      <div className=" w-[98%]  mx-auto dark:bg-slate-800 lg:h-[80vh] rounded-lg shadow-md mt-16 bg-[#dceaf1] custom-shadow">
        <div className="lg:grid  grid-cols-5 w-[90%] mx-auto gap-8 ">
          <div className="col-span-2 h-[70vh] w-[95%] mx-auto  lg:w-[80%] mt-10 contact-bg "></div>
          <div className="col-span-3 mt-10">
            <div className="lg:flex gap-10 items-center ">
              <div className="min-w-[40%] ">
                <form onSubmit={handleSubmit(submitHandler)}>
                  <p className="playrify text-2xl md:text-5xl text-gray-700 font-medium mt-10 dark:text-gray-100">
                    Contact Us
                  </p>

                  <div className=" mt-10">
                    <div className="w-full lg:max-w-xs">
                      <input
                        {...register("name", {
                          required: "Name is required",
                        })}
                        placeholder="Full Name"
                        type="text"
                        className="bg-transparent placeholder:text-gray-500 placeholder:text-lg focus:outline-none w-full dark:text-gray-100 dark:placeholder:text-gray-100"
                      />
                      <hr className="border-[1px] border-gray-700 mt-2 dark:border-gray-400" />
                      {errors.name && (
                        <p className="text-red-600">{errors.name.message}</p>
                      )}
                    </div>
                    <div className="w-full lg:max-w-xs mt-8">
                      <input
                        {...register("email", {
                          required: "Email is required",
                        })}
                        placeholder="Email"
                        type="email"
                        className="bg-transparent placeholder:text-gray-500 placeholder:text-lg focus:outline-none w-full dark:text-gray-100 dark:placeholder:text-gray-100"
                      />
                      <hr className="border-[1px] border-gray-700 mt-2 dark:border-gray-400" />
                      {errors.email && (
                        <p className="text-red-600">{errors.email.message}</p>
                      )}
                    </div>
                    <div className="w-full lg:max-w-xs mt-8">
                      <input
                          type="tel"
                          id="phoneNumber"
                          placeholder="Enter Phone Number"
                          {...register("phoneNumber", {
                            required: "Phone number is required",
                            pattern: {
                              value:
                                /^\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/,
                              message: "Please enter a valid US phone number",
                            },
                          })}
                        className="bg-transparent placeholder:text-gray-500 placeholder:text-lg focus:outline-none w-full dark:text-gray-100 dark:placeholder:text-gray-100"
                      />
                      <hr className="border-[1px] border-gray-700 mt-2 dark:border-gray-400" />
                      {errors.phoneNumber && (
                        <p className="text-red-600">{errors.phoneNumber.message}</p>
                      )}
                    </div>
                    <div className="w-full lg:max-w-xs mt-8">
                      <textarea
                        {...register("message", {
                          required: "Message is required",
                        })}
                        name="message"
                        placeholder="Message"
                        type="text"
                        className="bg-transparent placeholder:text-gray-500 placeholder:text-lg focus:outline-none w-full dark:text-gray-100 dark:placeholder:text-gray-100"
                      />
                      <hr className="border-[1px] border-gray-700 mt-2 dark:border-gray-400" />
                      {errors.message && (
                        <p className="text-red-600">{errors.message.message}</p>
                      )}
                    </div>
                  </div>

                  
                  <div className="mt-4">
                    <label className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-primary focus:outline-none"
                      />
                         <span className="text-xs leading-relaxed md:text-[15px] dark:text-gray-100   font-normal">
                        By checking this box, you agree to receive text
                        messages from Brooklyn Institute Of Vocational Training, you can
                        reply stop to opt-out at any time, this is our{" "}
                        <Link
                          className="text-blue-600"
                          to="/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          privacy policy
                        </Link>.
                      </span>
                    </label>
                  </div>

                 

                  <button
                    type="submit"
                    className="block w-full p-3 text-center rounded-md hover:bg-primary bg-gray-700 text-white px-12 py-3 mt-6"
                  >
                    Contact Us
                  </button>

                  {/* <button className="bg-gray-700 text-white mt-8 px-12 py-3 rounded-full hover-effect dark:text-gray-700 dark:bg-slate-200 ">
                    Contact Us
                  </button> */}
                </form>
              </div>

              <div className=" w-full lg:ml-[10%] py-10 lg:py-0">
                <div className="">
                  <p className="  text-xl font-bold text-gray-700 dark:text-gray-100">
                    Contact
                  </p>
                  <p className="mt-1 ">
                    <a
                      href="tel:+1929-386-0092"
                      className="text-lg text-gray-700 dark:text-gray-100 flex items-center gap-1 hover:text-primary"
                    >
                     <FaPhoneAlt/> 929-386-0092
                    </a>
                  </p>
                  <p className="mt-1 dark:text-gray-100">
                    <a
                      href="mailto:info@mybrooklyninstitute.com"
                      className="text-lg text-gray-700 dark:text-gray-100 flex items-center gap-1 hover:text-primary"
                    >
                      <MdEmail/> info@mybrooklyninstitute.com
                    </a>
                  </p>
                </div>
                <div className="mt-5">
                  <p className="  text-xl font-bold text-gray-700 dark:text-gray-100">
                    Address
                  </p>
                  <p className="flex items-center gap-2 text-gray-700 mt-2 ">
                    <MdOutlineLocationOn className="w-12 h-12 dark:text-gray-100" />
                    <a
                      href="https://www.google.com/maps/dir//110-05+Liberty+Ave,+Queens,+NY+11419/@40.6847005,-73.8317289,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c26750729d3653:0x592e70afeb742348!2m2!1d-73.8317289!2d40.6847005?hl=en"
                      target="_blank"
                      className="text-lg text-gray-700 dark:text-gray-100 hover:text-primary"
                      rel="noreferrer"
                    >
                      110-05 Liberty Avenue, Queens, New York 11419, United
                      States
                    </a>
                  </p>
                </div>
                <div className="mt-5">
                  <p className="  text-xl font-bold text-gray-700 dark:text-gray-100">
                    Hours
                  </p>
                  <div className="mt-1 ">
                    {/* <p className="text-2xl text-[#1b1b1b] mb-6">Hours</p> */}
                    <select
                      className="focus:outline-none text-lg rounded-md py-2 
              bg-transparent text-primary dark:bg-slate-600 dark:text-gray-100 font-medium border-[1px] border-primary dark:border-gray-100 cursor-pointer"
                    >
                      {all?.map((al, index) => (
                        <option value={al.selectedVal.val} key={index}>
                          {al.selectedText.text}
                        </option>
                      ))}
                      <option value="Mon">Mon 08:30 am – 08:00 pm</option>
                      <option value="Tue">Tue 08:30 am – 08:00 pm</option>
                      <option value="Wed">Wed 08:30 am – 08:00 pm</option>
                      <option value="Thu">Thu 08:30 am – 08:00 pm</option>
                      <option value="Fri">Fri 08:30 am – 08:00 pm</option>
                      <option value="Sat">Sat 08:30 am – 05:00 pm</option>
                      <option value="Sun">Sun 08:30 am – 05:00 pm</option>
                    </select>
                  </div>
                </div>

                <div className="flex items-center gap-6 md:text-lg font-medium md:mr-[5%] text-gray-700 mt-16 dark:text-gray-100">
                  <a
                    href="https://www.facebook.com/HHACertified/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsFacebook className="md:text-2xl hover-effect" />
                  </a>

                  <a
                    href="https://www.instagram.com/mybrooklyninstitute/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsInstagram className="md:text-2xl hover-effect" />
                  </a>
                  <a
                    href="https://twitter.com/cottagecareny"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsTwitter className="md:text-2xl hover-effect" />
                  </a>

                  <a
                    href="https://www.tiktok.com/@mybrooklyninstitute"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTiktok className="md:text-2xl hover-effect" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-20  lg:min-h[70vh] dark:bg-slate-600 ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.512893688839!2d-73.8317289!3d40.68470049999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26750729d3653%3A0x592e70afeb742348!2s110-05%20Liberty%20Ave%2C%20Queens%2C%20NY%2011419!5e0!3m2!1sen!2sus!4v1682091623420!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="w-full mx-auto h-[50vh] lg:h-[70vh]  shadow-md"
        >

        </iframe>
      </div>

      {/* <Chat/> */}
                      
      
    </div>
  );
};

export default Contact;
