import React, { useState } from 'react';
import { MdOutlineKeyboardDoubleArrowUp } from 'react-icons/md';
import { Button } from './Scroll';

const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
	<Button>
	<MdOutlineKeyboardDoubleArrowUp onClick={scrollToTop}
			style={{ display: visible ? 'inline' : 'none' }} className='bg-[#4985A1] text-white h-9 w-10 p-2 shadow-lg'/>
	</Button>
);
}

export default ScrollButton;
