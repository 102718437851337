import React from 'react';

const ClassModal = ({newClass}) => {

    const {img,description,startDate,classTitle} =newClass

//    console.log(newClass)
    return (
        <div>



{/* Put this part before </body> tag */}
<input type="checkbox" id="course-card" className="modal-toggle" />
<div className="modal">
  <div className="modal-box w-11/12 max-w-6xl bg-[#E8F3F8] dark:bg-slate-500">
    <label htmlFor="course-card" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <div className='grid md:grid-cols-2 gap-7 px-4 py-3 roboto items-center'>
        <div>

           <img src={img} className='w-[90%] lg:h-96 rounded-md shadow-lg h-64 ' alt="" />

        </div>

        <div>
            <h3 className='text-3xl font-semibold dark:text-gray-100'>
            {classTitle}
            </h3>
            <h5 className='mt-2 text-xl font-semibold dark:text-gray-100'>
               Start Date: {startDate}
            </h5>
            <h5 className='mt-1 text-xl font-semibold dark:text-gray-100'>
               End Date: {newClass?.endDate}
            </h5>

            <ul className='mt-2  dark:text-gray-100'>
                {
                        description?.map((des,index)=><li
                        key={index}

                        className='text-lg mt-1 font-medium'
                        
                        >

                            {des}

                            
                        </li>)
                }
                <li className="leading-relaxed dark:text-gray-100 text-lg mt-1 font-medium">${newClass?.registration} Registration | ${newClass?.tuition} Tuition | Textbook ${newClass?.books}</li>
                  <li className="leading-relaxed dark:text-gray-100 text-lg mt-1 font-medium">
                  Total: ${
                    parseInt(`${newClass?.registration}`) + parseInt(`${newClass?.tuition}`) +    parseInt(`${newClass?.books}`)
                  }
                  </li>
            </ul>

            <div className='mt-5'>
                  <a href="https://wa.me/13479752525?text=Hello%20there!" target='_blank'
                  
                  className='bg-primary px-4 py-2.5 rounded-md shadow-md uppercase text-white'
                  
                  >
                      Contact Us
                  </a>
            </div>


        </div>

    </div>
  </div>
</div>
            
        </div>
    );
};

export default ClassModal;