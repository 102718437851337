import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { generateBreadcrumbData } from "../Utils/breadcrumbUtils";
import { updateNavigationHistory } from "../Utils/navigationUtils";

const PageComponent = ({ title, description, keywords }) => {
  // console.log(keywords)
  const location = useLocation();
  const [breadcrumbData, setBreadcrumbData] = useState({});

  // Function to remove existing meta tags
  const clearMetaTags = () => {
    const metaTags = document.querySelectorAll(
      'meta[name="description"], meta[name="keywords"]'
    );
    metaTags.forEach((tag) => tag.remove());
  };

  useEffect(() => {
    updateNavigationHistory(location.pathname);

    // Clear previous meta tags when location changes
    clearMetaTags();

  }, [location.pathname]);



  useEffect(() => {
    const newBreadcrumbData = generateBreadcrumbData();
    // console.log("Breadcrumb Data:", newBreadcrumbData);
    setBreadcrumbData(newBreadcrumbData);
  }, [location?.pathname]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbData)}
        </script>
      </Helmet>
      {/* Page content */}
    </div>
  );
};

export default PageComponent;
