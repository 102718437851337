import React from "react";
import { BsFillTelephoneFill, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { MdPrivacyTip } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import logo from "../../../src/Assets/logo.png";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#F5F5F7] dark:bg-slate-600">
      <div className="bg-[#F5F5F7] py-5 dark:bg-slate-600">
        <div
          className="flex   text-center items-center justify-center    mx-auto px-4 py-4 w-[95%] md:w-[50%]
       border-[1px]  border-primary shadow-lg bg-hov2 "
        >
          <p className="dark:text-gray-100 text-gray-600 text-base md:text-2xl font-semibold Berlin uppercase ">
            Click to follow us on social media
          </p>
        </div>
      </div>
      <div className="footer-bg">
        <div className=" py-6">
          <div className=" grid md:grid-cols-2 lg:grid-cols-5 grid-cols-2 w-[97%] mx-auto shadow-sm">
            {/* facebook  */}
            <a
              className="flex justify-start pl-[8%] md:pl-0 md:justify-center bg-[#5271B3] py-3 cursor-pointer "
              href="https://www.facebook.com/profile.php?id=61559260233789"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" flex items-center gap-3 md:gap-5">
                <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-[#46619A] border-[1px] border-[#40598d] shadow-md ">
                  <FaFacebookF className="text-white  md:text-2xl"></FaFacebookF>
                </div>

                <div className="text-white text-sm md:text-base">
                  <p>Facebook</p>
                  <p>Join us on Facebook</p>
                </div>
              </div>
            </a>

            {/* end facebook */}

            {/* start instagram  */}

            <a
              className="flex justify-start pl-[8%] md:pl-0 md:justify-center bg-gradient-to-r from-[#E0406D] to-[#6162DC]  py-3 cursor-pointer"
              href="https://www.instagram.com/mybrooklyninstitute/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" flex items-center gap-3  md:gap-5">
                <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-gradient-to-t from-[#F08535] via-[#D63573] to-[#7135B0] border-[1px] border-[#7135B0] shadow-md ">
                  <GrInstagram className="text-white md:text-2xl"></GrInstagram>
                </div>

                <div className="text-white text-sm md:text-base">
                  <p>Instagram</p>
                  <p>Join us on Instagram</p>
                </div>
              </div>
            </a>

            {/* end instagram */}

            {/* start tweeter */}

            <a
              className="flex justify-start pl-[8%] md:pl-0 md:justify-center bg-[#59C8FF] py-3 cursor-pointer"
              href="https://twitter.com/cottagecareny"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" flex items-center gap-3 md:gap-5">
                <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-[#4CB3DB] border-[1px] border-[#55afd2] shadow-md ">
                  <BsTwitter className="text-white md:text-2xl"></BsTwitter>
                </div>

                <div className="text-white text-sm md:text-base">
                  <p>Twitter</p>
                  <p>Join us on Twitter</p>
                </div>
              </div>
            </a>

            {/* end tweeter */}

            {/* start Tiktok */}

            <a
              className="flex justify-start pl-[8%] md:pl-0 md:justify-center bg-gradient-to-r  from-gray-700 via-gray-400  to-[#65C7CD]  py-3 cursor-pointer"
              href="https://www.tiktok.com/@mybrooklyninstitute"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" flex items-center gap-3 md:gap-5">
                <div className="flex justify-center items-center  w-8 h-8 md:w-14 md:h-14 bg-gradient-to-t from-pink-400 via-[#65C7CD]  to-gray-800 border-[1px] border-pink-300 shadow-md ">
                  <FaTiktok className="text-white md:text-2xl "></FaTiktok>
                </div>

                <div className="text-white text-sm md:text-base">
                  <p>Tik Tok </p>
                  <p>Join us on Tik Tok</p>
                </div>
              </div>
            </a>

            {/* End Tiktok  */}
            {/* start Tiktok */}

            <a
              className="md:flex justify-start pl-[8%] md:pl-0 md:justify-center bg-[#EB1A21] py-3 cursor-pointer hidden"
              href="https://www.youtube.com/channel/UCTvZbyduRmtVxlBwrh7ikNw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" flex items-center gap-3 md:gap-5">
                <div className="flex justify-center items-center  w-8 h-8 md:w-14 md:h-14 bg-[#CA161C] border-[1px] border-[#b8151a] shadow-md ">
                  <BsYoutube className="text-white md:text-2xl"></BsYoutube>
                </div>

                <div className="text-white text-sm md:text-base">
                  <p>Youtube</p>
                  <p>Join us on Youtube</p>
                </div>
              </div>
            </a>

            {/* End Tiktok  */}
          </div>
          <div className="w-[50%] mx-auto">
            <a
              className="flex justify-start pl-[8%] md:pl-0 md:justify-center bg-[#EB1A21] py-3 cursor-pointer md:hidden shadow-sm"
              href="https://www.youtube.com/channel/UCTvZbyduRmtVxlBwrh7ikNw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" flex items-center gap-3 md:gap-5">
                <div className="flex justify-center items-center  w-8 h-8 md:w-14 md:h-14 bg-[#CA161C] border-[1px] border-[#b8151a] shadow-md ">
                  <BsYoutube className="text-white md:text-2xl"></BsYoutube>
                </div>

                <div className="text-white text-sm md:text-base">
                  <p>Youtube</p>
                  <p>Join us on Youtube</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="  px-4 np divide-y  text-white Berlin footer-shade footer-opacity pt-5">
          <div>
            <div className="container flex flex-col justify-between pt-10  mx-auto space-y-8 lg:flex-row lg:space-y-0">
              <div className="lg:w-1/3 ">
                <Link
                  to="/"
                  className="flex justify-center space-x-3 lg:justify-start"
                >
                  <div className="flex items-center justify-center w-16 h-16 rounded-full bg-white shadow-xl border-2 border-primary">
                    <img src={logo} alt="" className="p-2 " />
                  </div>
                  <span className="self-center text-2xl custom-font md:font-semibold">
                    Brooklyn Institute of <br /> Vocational Training
                  </span>
                </Link>
              </div>
              <div className="grid grid-cols-1 text-sm gap-x-3 gap-y-8 lg:w-2/3 md:grid-cols-2 lg:grid-cols-3 ">
                <div className=" space-y-2 lg:space-y-3">
                  <h3 className="tracking-wide uppercase text-white flex items-center gap-1 text-center md:text-start justify-center lg:justify-start">
                    Phone -<BsFillTelephoneFill />{" "}
                  </h3>
                  <ul className="space-y-1 text-center lg:text-start">
                    <li>
                      <a href="tel:+1929-386-0092" className="text-white ">
                        929-386-0092
                      </a>
                    </li>
                  </ul>
                </div>

                <div className=" space-y-2 lg:space-y-3 ">
                  <h3 className="uppercase text-white flex gap-1 items-center text-center md:text-start justify-center lg:justify-start">
                    Email - <MdEmail />
                  </h3>
                  <ul className="space-y-1 text-center lg:text-start">
                    <li>
                      <a
                        href="mailto:info@mybrooklyninstitute.com"
                        className="text-white "
                      >
                        info@mybrooklyninstitute.com{" "}
                      </a>
                    </li>
                  
                  
                  </ul>
                </div>

                
                <div className=" space-y-2 lg:space-y-3 ">
                  <Link to='/privacy-policy' className="uppercase text-white flex gap-1.5 items-center text-center md:text-start justify-center lg:justify-start">
                <MdPrivacyTip/>  Privacy Policy  
                  </Link>
                
                </div>
              </div>
            </div>
            <span className="text-center my-4 uppercase text-xs md:text-sm border-[1px] border-primary flex justify-center lg:w-[550px] mx-auto p-2">
              {" "}
              sms consent or phone numbers for the purpose of sms aren't shared
            </span>
          </div>

          <div className="py-6 text-sm text-center text-gray-50">
            Copyright © {currentYear} Brooklyn Institute of Vocational Training
            - All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
