import React from 'react';

const Loading = () => {
    return (
        <div className='my-5'>
            <div className="flex items-center justify-center space-x-2">
                <div className="w-4 h-4 rounded-full animate-pulse bg-[#CA4757]"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-[#CA4757]"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-[#CA4757]"></div>
            </div>
        </div>
    );
};

export default Loading;