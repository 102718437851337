import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { CgMenuGridR } from "react-icons/cg";
import { AuthContext } from "../../../Context/AuthProvider";
import useAdmin from "../../../Hooks/UseAdmin";
import useTitle from "../../../Hooks/useTitle";
import Footer from "../../Footer/Footer";
import { NavBar } from "../../NavBar/NavBar";
import { MdOutlineDashboard } from "react-icons/md";
import { AiFillFile, AiFillMail, AiOutlineHome } from "react-icons/ai";
import { SiGooglemessages } from "react-icons/si";
import { FaBookReader, FaGraduationCap } from "react-icons/fa";
import Loading from "../../../Shared/Loading";
import UpdateTime from "./UpdateTime/UpdateTime";
import { toast } from "react-hot-toast";

const DashBoardLayout = () => {
  useTitle("DashBoard");

  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [allUsers, setAllUsers] = useState([]);
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(false);

  // chat

  let userChat = 0;

  useEffect(() => {
    fetch("https://brooklyn-server-v4.vercel.app/users")
      .then((res) => res.json())
      .then((data) => setAllUsers(data));
  }, [userChat]);

  // console.log(allUsers?.users)

  if (allUsers?.length <= 0) {
    return <Loading></Loading>;
  } else {
    for (const chat of allUsers?.users) {
      if (chat?.chat) {
        userChat = userChat + 1;
      }
    }
  }

  // console.log(userChat)

  const mailHandler = () => {
    const fetchData = async () => {
      const proceed = window.confirm(
        `Are you sure, you want to send email about upcoming Classes to all mailing list  ?`
      );
      if (proceed) {
        setLoad(true);
        try {
          const response = await fetch(
            "https://brooklyn-server-v4.vercel.app/newsletter"
          ); // Replace with your backend API endpoint
          const data = await response.json();
          setData(data);
          setLoad(false);
          toast.success("All mails send successfully");
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoad(false);
          toast.error("Mails are not sent successfully");
        }
      }
    };

    fetchData();
  };

  if (load) {
    return <Loading></Loading>;
  }

  return (
    <div className="min-h-screen dark:bg-slate-600">
      <div className="sticky top-0 z-50">
        <NavBar></NavBar>
        <div className="my-3 block lg:hidden  ">
          <label
            htmlFor="dashboard-drawer"
            className=" drawer-button text-4xl text-[#CA4757]"
          >
            <CgMenuGridR />
          </label>
        </div>
      </div>
      <div className="drawer drawer-mobile ">
        <input
          id="dashboard-drawer"
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content">
          <Outlet></Outlet>
        </div>
        <div className="drawer-side lg:border-2  dark:border-[#E5E7EB] w-[70%] lg:w-fit bg-white lg:bg-[#EBF8F9]  lg:shadow-lg dark:bg-slate-600 dark:text-gray-100">
          <label htmlFor="dashboard-drawer" className="drawer-overlay"></label>

          {
            <ul className="menu p-4 w-80 text-base-content mt-5 ">
              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link to="/" className="flex items-center gap-4">
                  <AiOutlineHome></AiOutlineHome> Home
                </Link>
              </li>
              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link to="/dashboard" className="flex items-center gap-4">
                  <MdOutlineDashboard></MdOutlineDashboard> DashBoard
                </Link>
              </li>
              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link
                  to="/dashboard/allMessages"
                  className="flex items-center gap-3"
                >
                  <SiGooglemessages></SiGooglemessages>{" "}
                  <sup className="text-sm font-semibold text-red-500 -ml-1">
                    {userChat}
                  </sup>
                  Messages
                </Link>
              </li>

              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link
                  to="/dashboard/classForm"
                  className="flex items-center gap-3"
                >
                  <FaBookReader /> Add A Class
                </Link>
              </li>

              <UpdateTime></UpdateTime>

              <li className="my-1 dark:text-gray-100 text-lg text-center">
                <Link className="flex items-center gap-3" to='uploadAlbum'>
                <FaGraduationCap/> Add a Graduation Album
                </Link>
              </li>
              <li
                className="my-1 dark:text-gray-100 text-lg text-center"
                onClick={mailHandler}
              >
                <p className="flex items-center gap-3">
                  <AiFillMail></AiFillMail> Send Class Info to Mailing
                </p>
              </li>

              {/* <li className="my-1 dark:text-gray-100 text-lg text-center"
                        
                          
                          >
                            <Link
                            to='/dashboard/pdfUploader'
                            className="flex items-center gap-3">
                            <AiFillFile></AiFillFile> Upload A Notice 
                            </Link>
                          </li> */}

              {/* <li className="my-1 dark:text-gray-100 text-lg text-center">
                            <Link to='/dashboard/employee' className="flex items-center gap-3">
                            Add Employee <FaUserTie className="hover:text-primary" />
                            </Link>
                          </li>
                          <li className="my-1 dark:text-gray-100 text-lg text-center">
                          <Link to="/dashboard/blogForm">
                            
                            Add A Blog
                            <ImBlog  />
        
                          </Link>
                        </li> */}
            </ul>
          }
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default DashBoardLayout;
