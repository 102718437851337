import React from 'react';
import dedicatedEducator from '../../Assets/dedicated-educators.jpg';
import jobplacement from '../../Assets/job-placement.jpg';
import courseOffering from '../../Assets/offering-courses.jpg';

const AboutUs = () => {

    const abouts = [
        {   img: dedicatedEducator,
            title: 'Dedicated Educators',
            description: 'Our classes are taught by dedicated and experienced educators. Both of our instructors have over 30 years of experience as Registered Nurses.'
        },
        {
            img: jobplacement,
            title: 'Immediate Job Placement',
            description: 'Your success is our priority. To support you, we have partnered with our sister agency, Cottage Home Care Services, to get you started and working as fast as a day after graduation!'
        },
        {
            img: courseOffering,
            title: 'First-Rate Course Offerings',
            description: 'We embrace a learning environment that will prepare you for the path ahead. Our classes incorporate traditional learning styles as well as hands-on experiences.'
        },
    ]
    return (
        <div className='pt-14 pb-5 about-banner'>
            <h1 className='text-center playrify text-2xl font-bold text-[#A13744] md:text-5xl dark:text-gray-100'> About Us </h1>
            <div className='flex justify-center mt-2'>
                <hr className='w-14 border-t-[2px] border-gray-500 dark:border-gray-100' />
            </div>

            <div className=' grid md:grid-cols-2 lg:grid-cols-3 w-[95%] md:w-[90%] lg:w-[80%] mx-auto gap-8 mt-10'>

                {
                    abouts?.map((about, index) => <div

                        key={index}
                        className='text-center border-[1px] border-[#CA4757]  rounded-md shadow-lg hover-effect2 bg-white dark:bg-slate-600'
                    >
                        <div className=''>
                            <img src={about?.img} alt="" className='w-full h-64 rounded-t-md' />

                        </div>
                        <div className='px-4 py-5'>
                        <h3 className='text-lg md:text-2xl font-semibold mb-3 dark:text-gray-100'>
                            {about?.title}
                        </h3>
                        <p className='text-base text-gray-600 font-normal Poppins text-justify md:tracking-tight tracking-tighter dark:text-gray-200'>
                            {about?.description}

                        </p>

                        </div>

                        

                    </div>)
                }



            </div>
        </div>
    );
};

export default AboutUs;