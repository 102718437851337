import React, { useContext, useEffect, useState } from "react";
import { ImHome } from "react-icons/im";
import {
  MdDashboard,
  MdLightMode,
  MdOutlineMedicalServices,
} from "react-icons/md";
import { IoBookSharp, IoDesktopOutline, IoMoonSharp } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import useAdmin from "../../Hooks/UseAdmin";
import { AuthContext } from "../../Context/AuthProvider";
import { FaGraduationCap } from "react-icons/fa";
import { BiSolidVideos } from "react-icons/bi";

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const sliderCloser = () => {
    setIsOpen(false);
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "system"
  );
  const { user, logOut } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);

  // console.log(isAdmin)
  const handleLogOut = () => {
    logOut()
      .then(() => {})
      .catch((err) => console.log(err));
  };

  // #164273

  const element = document.documentElement;

  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
  // console.log(darkQuery, "darkQuery")

  const options = [
    {
      icon: <MdLightMode />,
      text: "light",
    },
    {
      icon: <IoMoonSharp />,
      text: "dark",
    },
    {
      icon: <IoDesktopOutline />,
      text: "system",
    },
  ];

  function onWindowMatch() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }

  onWindowMatch();

  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");

        break;

      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");

        break;

      default:
        localStorage.removeItem("theme");
        onWindowMatch();
        break;
    }
  }, [theme]);

  darkQuery.addEventListener("change", (e) => {
    if (!("theme" in localStorage)) {
      if (e.matches) {
        element.classList.add("dark");
      } else {
        element.classList.remove("dark");
      }
    }
  });

  return (
    <div className="  z-50">
      {/* Sidebar Toggle Button */}
      <button
        onClick={toggleSidebar}
        className=" p-2 rounded-md bg-primary text-white  focus:outline-none"
      >
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </button>

      {/* Sidebar Content */}
      <div
        className={`${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } fixed top-0 left-0 h-screen w-[75%] text-white p-4 transition-transform duration-300 ease-in-out transform overflow-y-auto`}
        style={{
          backgroundColor: isOpen ? "rgba(0, 0, 0, 0.8)" : "transparent",
        }}
      >
        <ul id="nav-text" className="space-y-5">
          <li>
            <div className=" rounded-md  p-2 flex justify-evenly items-center border-[1px] border-gray-100 shadow-2xl">
              {options?.map((opt, index) => (
                <button
                  key={index}
                  onClick={() => setTheme(opt?.text)}
                  className={`h-7 w-7 text-xl ${
                    theme === opt?.text && "text-sky-600"
                  }`}
                >
                  {opt?.icon}
                </button>
              ))}
            </div>
          </li>
          <li onClick={sliderCloser}>
            <NavLink
              to="/"
              aria-label="Home"
              title="Home"
              className={`font-medium    tracking-wide  transition-colors duration-200 uppercase nav-text hover-underline-animation  text-gray-100 flex gap-2`}
            >
              <ImHome className="font-medium mt-[1.5px]" /> Home
            </NavLink>
          </li>
          <li onClick={sliderCloser}>
            <NavLink
              to="/classes"
              aria-label="Classes"
              title="Classes"
              className={`font-medium    tracking-wide  transition-colors duration-200  uppercase nav-text hover-underline-animation text-gray-100 flex gap-2 items-center`}
            >
              <IoBookSharp className="font-medium " />
              Classes
            </NavLink>
          </li>

          <li onClick={sliderCloser}>
            <NavLink
              to="/contact"
              aria-label="Contact US"
              title="Contact US"
              className={`font-medium  tracking-wide  transition-colors duration-200  
                                uppercase nav-text hover-underline-animation text-gray-100 flex gap-2 `}
            >
              <BsFillTelephoneFill className="font-medium mt-[1.5px]" /> Contact
              US
            </NavLink>
          </li>
          <li onClick={sliderCloser}>
            <NavLink
              to="/graduation"
              aria-label="Graduation"
              title="Graduation"
              className={`font-medium  tracking-wide  transition-colors duration-200  
                                uppercase nav-text hover-underline-animation text-gray-100 flex gap-2 `}
            >
              <FaGraduationCap className="font-medium mt-[1.5px]" /> Graduation
            </NavLink>
          </li>
          <li onClick={sliderCloser}>
            <NavLink
              to="/videos"
              aria-label="videos"
              title="videos"
              className={`font-medium  tracking-wide  transition-colors duration-200  
                                uppercase nav-text hover-underline-animation text-gray-100 flex gap-2 `}
            >
              <BiSolidVideos className="font-medium mt-[1.5px]" /> Video Gallery
            </NavLink>
          </li>

          {user?.uid && (
            <>
              {isAdmin && (
                <>
                  <li onClick={sliderCloser}>
                    <NavLink
                      to="/dashBoard"
                      aria-label="Dash Board"
                      title="Dash Board"
                      className={`fo font-medium  text-base  tracking-wide  transition-colors duration-200  
                                 uppercase nav-text hover-underline-animation text-gray-100 flex gap-2 items-center`}
                    >
                      <MdDashboard /> Dash Board
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
          {user?.uid ? (
            <>
              <li className="nav-user">
                <img
                  src={user?.photoURL}
                  title={user?.displayName}
                  className="rounded-full h-12 w-12 border-primary border-[2px] nav-text"
                  alt=""
                />
              </li>

              <li>
                <button
                  onClick={handleLogOut}
                  className="bg-primary  lg:px-6 lg:py-3 px-3 py-2 lg:text-base  rounded-full  bg-hov2  uppercase nav-text text-gray-100"
                >
                  Sign Out
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink
                  to="/login"
                  aria-label="Sign Up"
                  title="Sign Up"
                  className={`font-medium  text-lg  tracking-wide  transition-colors duration-200  
                               text-gray-100`}
                >
                  <button className="bg-primary  lg:px-6 lg:py-3 px-3 py-2 lg:text-base  rounded-full bg-hov2 uppercase nav-text  text-gray-100">
                    Sign In
                  </button>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
