import React from 'react';

const UpcommingModal = (upcomming) => {

    // console.log(upcomming)

    const today = Date.now();
    
   
    return (
        <div>



{/* Put this part before </body> tag */}
<input type="checkbox" id="upcomming-card" className="modal-toggle" />
<div className="modal">
  <div className="modal-box w-11/12 max-w-6xl bg-[#E8F3F8] dark:bg-slate-500">
    <label htmlFor="upcomming-card" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
    <div className='grid md:grid-cols-2 gap-7 px-4 py-3 roboto items-center'>
        <div>

           <img src={upcomming?.upcomming?.img} className='w-[90%] lg:h-96 rounded-md shadow-lg h-64 ' alt="" />

        </div>

        <div>
            <h3 className='text-3xl font-semibold dark:text-gray-100'>
            {upcomming?.upcomming?.classTitle}
            </h3>
      
           
            <h5 className='mt-2 text-xl font-semibold dark:text-gray-100'>
              Start Date: {upcomming?.upcomming?.startDate}
            </h5>
         
            <h5 className='mt-1 text-xl font-semibold dark:text-gray-100'>
              End Date: {upcomming?.upcomming?.endDate}
            </h5>
        
            

            {/* {
            upcomming?.upcomming?.startDate2 && new Date(upcomming?.upcomming?.startDate2.replace(/(\d+)(th|st|nd|rd)/, '$1')).getTime() >= today &&
            <h5 className='mt-2 text-xl font-semibold dark:text-gray-100'>
                {upcomming?.upcomming?.startDate2}
            </h5>
            } */}
            {
             upcomming?.upcomming?.startDate3 && new Date(upcomming?.upcomming?.startDate3.replace(/(\d+)(th|st|nd|rd)/, '$1')).getTime() >= today &&
            <h5 className='mt-2 text-xl font-semibold dark:text-gray-100'>
                {upcomming?.upcomming?.startDate3}
            </h5>
            }

            <ul className='mt-2 '>
                {
                        upcomming?.upcomming?.description?.map((des,index)=><li
                        key={index}

                        className='text-lg mt-1 font-medium dark:text-gray-100'
                        
                        >

                            {des}
                        </li>)
                }
                                  <li className="text-lg mt-1 font-medium dark:text-gray-100">${upcomming?.upcomming?.registration} Registration | ${upcomming?.upcomming?.tuition} Tuition | Textbook ${upcomming?.upcomming?.books}</li>
                  <li className="text-lg mt-1 font-medium dark:text-gray-100">
                  Total: ${
                    parseInt(`${upcomming?.upcomming?.registration}`) + parseInt(`${upcomming?.upcomming?.tuition}`) +    parseInt(`${upcomming?.upcomming?.books}`)
                  }
                  </li>
            </ul>

            <div className='mt-5'>
                  <a href="https://wa.me/13479752525?text=Hello%20there!" target='_blank'
                  
                  className='bg-primary px-4 py-2.5 rounded-md shadow-md uppercase text-white'
                  
                  >
                      Contact Us
                  </a>
            </div>


        </div>

    </div>
  </div>
</div>
            
        </div>
    );
};

export default UpcommingModal;