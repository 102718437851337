const pageMappings = {
    '/': { name: 'Home', path: '/' },
    '/classes': { name: 'Classes', path: '/classes' },
    '/contact': { name: 'Contact Us', path: '/contact' },
    '/graduation': { name: 'GRADUATION', path: '/graduation' },
    '/videos': { name: 'Video Gallery', path: '/videos' },
    
  };
  
  
  export const generateBreadcrumbData = () => {
    
    const navigationHistory = JSON.parse(sessionStorage?.getItem('navigationHistory')) || [];
  
    const breadcrumbData = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": []
    };
  
    navigationHistory?.forEach((path, index) => {
      let pageDetail = pageMappings[path];
  
      if (!pageDetail) {
        // Detect and handle dynamic routes
        for (const route in pageMappings) {
          const regex = new RegExp(route.replace(/:[^\s/]+/g, '[^/]+')); // Convert dynamic route to regex
          if (regex.test(path)) {
            pageDetail = pageMappings[route];
            break;
          }
        }
      }
  
      if (pageDetail) {
        breadcrumbData.itemListElement.push({
          "@type": "ListItem",
          "position": index + 1, // Adjust position to start from 1
          "name": pageDetail.name,
          "item": `https://mybrooklyninstitute.com${path}`
        });
      }
    });
  
    // console.log("Breadcrumb Data:", breadcrumbData);
    return breadcrumbData;
  };
  