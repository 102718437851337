import React from 'react';
import { BsFacebook, BsFillTelephoneFill, BsInstagram, BsTwitter } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import GoogleTranslate from './GoogleTranslate/GoogleTranslate';

const TopBar = () => {
    return (
        <div className='dark:bg-slate-800'>
            <div className=' block w-[95%] mx-auto'>
                <div className='flex justify-end md:justify-center lg:justify-between items-center '>
                    <div className='roboto px-8 py-3 lg:flex items-center lg:justify-between lg:w-[95%]'>
                        <div className='lg:flex gap-8 hidden'>
                            <div className='flex gap-4 items-center md:text-lg dark:text-gray-100' id='topbar-text'>
                                <BsFillTelephoneFill />
                                <p>
                                    Call  <a href="tel:+1929-386-0092" className='text-[#CA4757] dark:text-gray-100'> 929-386-0092</a>
                                </p>

                            </div>
                            <div className='flex gap-4 items-center md:text-lg dark:text-gray-100' id='topbar-text1'>
                                <TfiEmail />
                                <p>
                                    Email  <a href="mailto:info@mybrooklyninstitute.com" className='text-[#CA4757] dark:text-gray-100'>info@mybrooklyninstitute.com</a>
                                </p>

                            </div>
                        </div>

                        <div className='flex items-center gap-4 md:gap-5 md:text-lg font-medium  text-[#CA4757]'>

                            <a href="tel:+1929-386-0092" className='text-[#CA4757]
                             md:text-2xl hover-effect lg:hidden  dark:text-gray-100'>  <BsFillTelephoneFill /></a>

                            <a href="mailto:info@mybrooklyninstitute.com" className='text-[#CA4757] md:text-2xl hover-effect  lg:hidden dark:text-gray-100'> <TfiEmail /></a>

                            <a href="https://www.facebook.com/profile.php?id=61559260233789" target='_blank' rel="noreferrer"><BsFacebook className='md:text-2xl hover-effect dark:text-gray-100 topbar-icon' /></a>

                            <a href="https://www.instagram.com/mybrooklyninstitute/" target='_blank' rel="noreferrer">
                                <BsInstagram className='md:text-2xl hover-effect dark:text-gray-100 topbar-icon' />
                            </a>
                            <a href="https://twitter.com/cottagecareny" target='_blank' rel="noreferrer">
                                <BsTwitter className='md:text-2xl hover-effect dark:text-gray-100 topbar-icon' />

                            </a>
                            <a href="https://www.tiktok.com/@mybrooklyninstitute" target='_blank' rel="noreferrer">
                                <FaTiktok className='md:text-2xl hover-effect dark:text-gray-100 topbar-icon' />

                            </a>




                        </div>


                    </div>
                    <div>
                        <GoogleTranslate />
                    </div>
                </div>
            </div>

            <hr className='' />
        </div>
    );
};

export default TopBar;