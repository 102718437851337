import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import Loading from "../../../Shared/Loading";

const AreaRechartComponent = () => {
  const [selectedDate, setSelectedDate] = useState(new Date()); // default to current date

  // Extract year and month from selected date
  const selectedYear = selectedDate ? selectedDate.getFullYear() : new Date().getFullYear();
  const selectedMonth = selectedDate ? selectedDate.getMonth() + 1 : new Date().getMonth() + 1;

  const url = `https://brooklyn-server-v4.vercel.app/visitors/dailyCount?year=${selectedYear}&month=${selectedMonth}`;
  // console.log(url);

  const { data: visitors = [], isLoading, error, refetch } = useQuery({
    queryKey: ["dailyCount", selectedYear, selectedMonth],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
    enabled: !!selectedDate, // Only run query if a valid date is selected
  });



  const handleDateChange = (date) => {
    setSelectedDate(date || new Date()); // If date is null, reset to current date
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error loading data...</div>;
  }

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <label className="dark:text-gray-100">Select Month: </label>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className=" px-2 dark:bg-slate-500 dark:text-gray-100 py-0.5 border border-gray-400 dark:border-gray-100 rounded-sm focus:outline-none"
          onKeyDown={(e) => {
            if (e.key === 'Backspace' || e.key === 'Delete') {
              e.preventDefault(); // Prevent manual deletion, resetting to current date instead
              handleDateChange(null);
            }
          }}
        />
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          data={visitors}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" style={{ fontSize: "14px" }} />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="visitors"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorUv)"
          >
            <LabelList dataKey="visitors" position="top" />
          </Area>
          
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaRechartComponent;
