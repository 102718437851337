import { useContext, useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../src/Assets/final-logo.png";
import { AuthContext } from "../../Context/AuthProvider";
import useAdmin from "../../Hooks/UseAdmin";
import "./Nav.css";
import SideNav from "./SideNav";

import { MdBrightnessAuto, MdDashboardCustomize, MdLightMode, MdLogin, MdLogout } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoDesktopOutline, IoMoonSharp } from "react-icons/io5";

export const NavBar = () => {
  const [navColor, setNavColor] = useState("");

  const { user, logOut } = useContext(AuthContext);
  // console.log(user)
  const [isAdmin] = useAdmin(user?.email);
  const handleLogOut = () => {
    logOut()
      .then(() => {})
      .catch((err) => console.log(err));
  };

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // top-0 z-50
  // #164273

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "system"
  );

  window.onscroll = function () {
    myFunction();
  };

  function myFunction() {
    if (window.scrollY > 5) {
      setNavColor("w-[100%] fixed top-0 z-50 shadow-xl ");
    }
    if (window.scrollY < 5) {
      setNavColor("");
    }
  }


  const element = document.documentElement;

  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
  // console.log(darkQuery, "darkQuery")

  const options = [
    { icon: <MdLightMode />, text: "light" },
    { icon: <IoMoonSharp />, text: "dark" },
    { icon: <MdBrightnessAuto/>, text: "system" },
  ];


  function onWindowMatch() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }

  onWindowMatch();

  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");

        break;

      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");

        break;

      default:
        localStorage.removeItem("theme");
        onWindowMatch();
        break;
    }
  }, [theme]);

  darkQuery.addEventListener("change", (e) => {
    if (!("theme" in localStorage)) {
      if (e.matches) {
        element.classList.add("dark");
      } else {
        element.classList.remove("dark");
      }
    }
  });

  return (
    <div className={`${navColor} bg-white dark:bg-slate-800 `}>
      <div className="px-4 md:py-4 py-3 md:px-24 lg:px-16 nav-padding  ">
        <div className="relative flex items-center justify-between ">
          <Link
            to="/"
            aria-label="Brooklyn Institute of Vocational Training"
            title="Brooklyn Institute of Vocational Training"
            className="md:inline-flex items-center hidden "
          >
            <img
              src={logo}
              className="w-20 h-20 rounded-full border-2 border-[#EA5753]  nav-img dark:bg-gray-200"
              alt=""
            />

            <h1 className="ml-5 text-xl md:text-xl lg:text-3xl font-bold tracking-wide   transition-colors duration-200 text-[#CA4757] customNav-text roboto">
              Brooklyn Institute of Vocational Training
              <hr className="border-[1px] -mt-0.5 border-[#49465D]" />
              <p className="font-semibold top-title text-center text-[#49465D]">
                <hr className="border-[1px] -mt-0.5 border-primary dark:border-gray-100" />
                <p className="font-semibold text-base text-start md:text-center text-[#49465D] top-title dark:text-gray-100">
                  Become A Certified HHA Today
                </p>
              </p>
            </h1>
          </Link>
          

<Link className="header-container flex items-center md:hidden  ">
                <img
                  src={logo}
                  className="logo shadow-2xl border-2 border-primary rounded-full dark:bg-gray-300"
                  alt="Brooklyn institute Of Vocational Training Logo"
                  width="70"
                  height="70"
                />

                <div className="text-container ml-3 league-spartan">
                  <h5 className="title  text-primary tracking-wide  font-bold uppercase leading-loose">
                    
                    Brooklyn Institute of <br /> Vocational Training
                  </h5>
                  <p className="  dark:border-gray-100 mt-[2px]  border-t-[1px] border-gray-700" />
                  <p className="tagline  font-semibold text-[#49465D] dark:text-gray-100 mt-[1px]">
                  Become A Certified HHA Today
                  </p>
                </div>
              </Link>


          <ul
            className="flex items-center hidden space-x-6 lg:flex"
            id="nav-ul"
          >
            <li>
              <Link
                to="/"
                aria-label="Home"
                title="Home"
                className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase hover:text-[#CA4757] roboto dark:text-gray-100"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/classes"
                aria-label="classes"
                title="classes"
                className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase hover:text-[#CA4757] roboto dark:text-gray-100"
              >
                classes
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                aria-label="Contact Us"
                title="Contact Us"
                className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase hover:text-[#CA4757] roboto dark:text-gray-100"
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                to="/graduation"
                aria-label=" Graduation"
                title="Graduation"
                className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase hover:text-[#CA4757] roboto dark:text-gray-100"
              >
                Graduation
              </Link>
            </li>
            <li>
              <Link
                to="/videos"
                aria-label=" Graduation"
                title="Graduation"
                className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase hover:text-[#CA4757] roboto dark:text-gray-100"
              >
                Video Gallery
              </Link>
            </li>

            <button
      onClick={() =>
        setTheme((prev) => {
          const currentIndex = options.findIndex((opt) => opt.text === prev);
          return options[(currentIndex + 1) % options.length].text;
        })
      }
      className="relative w-16 h-8 rounded-full bg-gray-300 dark:bg-gray-700 flex items-center px-1 py-2 transition-all shadow-sm"
    >
      {/* Dynamic Circle */}
      <span
        className={`absolute w-5 h-5 rounded-full p-[3px] bg-white dark:bg-black flex justify-center items-center text-black dark:text-white transition-all ${
          theme === "dark"
            ? "translate-x-8"
            : theme === "system"
            ? "translate-x-4"
            : "translate-x-0"
        }`}
      >
        {
          options.find((opt) => opt.text === theme)?.icon // Render the correct icon
        }
      </span>
    </button>


            {/* <li>
              <Link
                to="/blog"
                aria-label="Blog"
                title="blog-page"
                className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase hover:text-[#CA4757] roboto dark:text-gray-100"
              >
                Blog
              </Link>
            </li> */}

            <div className="relative py-4">
              <div className=" inline-block text-left " ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-primary"
                >
                  <GiHamburgerMenu className="text-3xl font-semibold dark:text-gray-100" />
                </button>

                {isOpen && (
                  <div className="absolute text-gray-700 dark:text-gray-100 -right-2  border dark:border border-gray-200 rounded-md shadow-xl outline-none z-20 w-48 mt-3 py-3 dark:bg-slate-600 roboto bg-slate-100 space-y-3">
                    {user?.uid ? (
                      <>
                        <li className=" flex items-center gap-2">
                          <img
                            src={user?.photoURL}
                            title={user?.displayName}
                            className="rounded-full h-12 w-12 border-[#CA4757] border-[2px] ml-2 "
                            alt=""
                          />
                          <p className="font-medium uppercase roboto ">{user?.displayName}</p>
                        </li>

                        <li>
                          <Link
                            to="/login"
                            onClick={handleLogOut}
                            className="font-medium tracking-wide transition-colors duration-200 flex items-center justify-start gap-1 hover:text-deep-purple-accent-400 uppercase  roboto dark:text-gray-100 hover:bg-slate-300 px-2 text-base py-1"
                            aria-label="Sign up"
                            title="Sign up"
                          >
                           <MdLogout/>  Sign Out
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link
                            to="/login"
                            className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase  roboto dark:text-gray-100 hover:bg-slate-300 px-2 text-base py-1"
                            aria-label="Sign up"
                            title="Sign up "
                          >
                           <MdLogin/> Sign up
                          </Link>
                        </li>
                      </>
                    )}

                    {user?.uid && (
                      <>
                        {isAdmin && (
                          <>
                            <p>
                              <NavLink
                                to="/dashBoard"
                                aria-label="Dash Board"
                                title="Dash Board"
                                className="font-medium tracking-wide transition-colors duration-200 flex items-center gap-1 hover:text-deep-purple-accent-400 uppercase  roboto dark:text-gray-100 hover:bg-slate-300 px-4 text-base py-1"
                              >
                                <MdDashboardCustomize /> Dash Board
                              </NavLink>
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </ul>

          <div className="lg:hidden z-50 ">
            <div className="w-8 h-8">
              <SideNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
