import { GoogleAuthProvider } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FcGoogle } from 'react-icons/fc';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import loginCover from '../../../src/Assets/Login/login.png';
import { AuthContext } from '../../Context/AuthProvider';
import './Login.css';
import useTitle from '../../Hooks/useTitle';


const Login = () => {
    
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [showPass, setShowPass] = useState(false)
    const [loginError, setLoginError] = useState('')
    const { signIn, providerLogin } = useContext(AuthContext)
    const googleProvider = new GoogleAuthProvider();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const date = Date.now();

    useTitle('Login')

   

      const handelLogin =(data)=>{

        setLoginError('')
        signIn(data.email, data.password)
            .then(result => {
                const user = result.user
                // console.log(user)
                toast.success('User Login Successfully')
                reset()
                navigate(from, { replace: true });
                
                
            })
            .catch(error => {
                console.error(error.message)
                setLoginError(error.message)
            });
            
    }

    const googleSignInHandler = () => {
        providerLogin(googleProvider)
            .then(result => {
                const user = result.user
                // console.log(user)
                // toast.success('User Login SuccessFully')
                saveUser(user?.displayName, user?.email, user?.photoURL)
                navigate(from, { replace: true });

            })
            .catch(error => {
              console.error(error)
          })
    }
    const saveUser = (name, email, photoURL, verify = "false",time=date) => {
        const user = { name, email, photoURL, verify,time };
        fetch('https://brooklyn-server-v4.vercel.app/users', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .then(data => {
            //    console.log(data)
                if (data.acknowledged) {
                    toast.success('User Login Successfully')
                    
                }

            })



      }
      

    return (
        <div className='min-h-screen roboto mb-16 md:mb-0 dark:bg-slate-600 pt-5'>
            <div className='grid md:grid-cols-2 border-[1px] border-gray-300 custom-shadow lg:w-[85%] mx-auto rounded-md w-[95%]  '>
                <div className='bg-[#E0DCDC] h-[50vh] lg:min-h-[80vh] pt-[10vh] '>
                    <img src={loginCover} alt="" className='' />

                </div>

                <div className='w-[90%] mx-auto'>
                    <h1 className='mt-5 md:text-2xl font-semibold dark:text-gray-100 text-center'>
                    Welcome to 
                    </h1>
                    <h3 className='mt-2 md:text-xl font-semibold text-[#CA4757] dark:text-gray-100 text-center'>
                    Brooklyn Institute of Vocational Training
                    </h3>

                    <div className='mt-10'>
                       
                        <button
                        onClick={googleSignInHandler}
                        className='custom-button w-[80%] mx-auto  py-2 font-semibold flex items-center justify-center gap-5 hover-effect dark:text-gray-100'>
                            <FcGoogle className='text-3xl '/>
                            Login with Google
                        </button>
                       
                        
                        

                        
                       
                        
                    </div>

                    <div className='mt-6 flex gap-5 items-center justify-center '>
                       <p className='w-[40%]  border-t-[1px] border-gray-600 '></p>
                        <p className='dark:text-gray-100'>OR</p>
                       <p className='w-[40%]  border-t-[1px] border-gray-600'></p>
                        
                        
                    </div>
                    <div className='mt-8'>
                    <form onSubmit={handleSubmit(handelLogin)}  className="space-y-6 ng-untouched ng-pristine ng-valid">
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <label htmlFor="email" className="block text-sm  text-gray-600 dark:text-gray-100">Email address</label>
                                    <input type="email"
                                        {...register("email",
                                            { required: "email is required" })}
                                        id="email" placeholder="Your Email" className="w-full px-8 py-2  rounded-md bg-[#F2F2F2]  text-gray-700 placeholder:text-gray-500 focus:outline-none"  />
                                    {errors.email && <p className='text-red-600'>{errors.email.message}</p>}
                                </div>
                                <div className="space-y-2 relative">
                                    <div className="flex justify-between">
                                        <label htmlFor="password" className="text-sm dark:text-gray-100">Password</label>
                                    </div>
                                    <input type={showPass ? 'text' : "password"}
                                        {...register("password",
                                            { required: "password is required" })}
                                        id="password" placeholder="*****" className="w-full px-8 py-2 relative  rounded-md bg-[#F2F2F2]  text-gray-700 placeholder:text-gray-500 focus:outline-none" />
                                    {errors.password && <p className='text-red-600'>{errors.password.message}</p>}
                                    <div className="absolute right-3 top-7 text-gray-700" onClick={() => setShowPass(!showPass)}>
                                        {showPass ? <AiFillEyeInvisible className='h-6 w-6' /> : <AiFillEye className='h-6 w-6' />}
                                    </div>
                                 
                                </div>
                                {
                                    loginError && <p className='text-red-600'>{loginError}</p>
                                }
                               
                            </div>

                            <button className="w-full px-8 py-2.5  rounded-md sign-button text-white  bg-[#CA4757]">Sign in</button>
                         
                        </form>
                    </div>
                    <p className="text-sm text-start mt-5  text-gray-600 dark:text-gray-100">Don't have account?
                            <Link to='/register' className='underline ml-3 text-gray-600 text-lg dark:text-gray-100'>Sign up here</Link>
                        </p>


                </div>

            </div>
            
        </div>
    );
};

export default Login;