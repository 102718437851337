import React, { useEffect, useState } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { useLocation } from 'react-router-dom';
import useTitle from '../../Hooks/useTitle';
import ProgressiveImage from './ProgressiveImage';
import { ClipLoader } from 'react-spinners';

import img1 from '../../../src/Assets/Weekend-07-28-2024/IMG1.jpg'
import img2 from '../../../src/Assets/Weekend-07-28-2024/img2.jpg'
import img3 from '../../../src/Assets/Weekend-07-28-2024/img25.jpg'
import img4 from '../../../src/Assets/Weekend-07-28-2024/img4.jpg'
import img5 from '../../../src/Assets/Weekend-07-28-2024/img5.jpg'
import img6 from '../../../src/Assets/Weekend-07-28-2024/img6.jpg'
import img7  from '../../../src/Assets/Weekend-07-28-2024/img7.jpg'
import img8  from '../../../src/Assets/Weekend-07-28-2024/img8.jpg'
import img9  from '../../../src/Assets/Weekend-07-28-2024/img9.jpg'
import img10  from '../../../src/Assets/Weekend-07-28-2024/img10.jpg'
import img11  from '../../../src/Assets/Weekend-07-28-2024/img11.jpg'
import img12  from '../../../src/Assets/Weekend-07-28-2024/img12.jpg'
import img13  from '../../../src/Assets/Weekend-07-28-2024/img13.jpg'
import img14  from '../../../src/Assets/Weekend-07-28-2024/img14.jpg'
import img15  from '../../../src/Assets/Weekend-07-28-2024/img15.jpg'
import img16  from '../../../src/Assets/Weekend-07-28-2024/img16.jpg'
import img17  from '../../../src/Assets/Weekend-07-28-2024/img17.jpg'
import img18  from '../../../src/Assets/Weekend-07-28-2024/img18.jpg'
import img19  from '../../../src/Assets/Weekend-07-28-2024/img19.jpg'
import img20  from '../../../src/Assets/Weekend-07-28-2024/img20.jpg'
import img21  from '../../../src/Assets/Weekend-07-28-2024/img21.jpg'
import img22  from '../../../src/Assets/Weekend-07-28-2024/img22.jpg'
import img23  from '../../../src/Assets/Weekend-07-28-2024/img23.jpg'
import img24  from '../../../src/Assets/Weekend-07-28-2024/img24.jpg'
import img25  from '../../../src/Assets/Weekend-07-28-2024/img26.jpg'
import img26  from '../../../src/Assets/Weekend-07-28-2024/img27.jpg'
import img27  from '../../../src/Assets/Weekend-07-28-2024/img28.jpg'
import img29  from '../../../src/Assets/Weekend-07-28-2024/img29.jpg'
import img30  from '../../../src/Assets/Weekend-07-28-2024/img30.jpg'
import img31  from '../../../src/Assets/Weekend-07-28-2024/img31.jpg'
import img32  from '../../../src/Assets/Weekend-07-28-2024/img32.jpg'
import img33  from '../../../src/Assets/Weekend-07-28-2024/img33.jpg'
import img34  from '../../../src/Assets/Weekend-07-28-2024/img34.jpg'
import img35  from '../../../src/Assets/Weekend-07-28-2024/IMG35.jpg'
import img36  from '../../../src/Assets/Weekend-07-28-2024/img36.jpg'
import img37  from '../../../src/Assets/Weekend-07-28-2024/img37.jpg'


const Weekend07282024 = () => {

    

    const location = useLocation()
    useTitle(location.pathname.substring(1))

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleImageLoad = () => setLoading(false);
        const images = [
            img1, img2, img3, img4, img5, img6, img7, img8, img9,
            img11
        ];
        let loadedImages = 0;

        images?.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImages += 1;
                if (loadedImages === images.length) {
                    handleImageLoad();
                }
            };
        });
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen dark:bg-slate-600">
                <ClipLoader color="#3498db" loading={loading} size={150} className="loader-large" />
            </div>
        );
    }

    return (
        <div className='dark:bg-slate-600'>
            <div className="weekEnd07282024-banner min-h-[80vh] relative">
                <h1 className='lg:pt-[65vh] pt-[55vh] text-center text-white  text-2xl md:text-5xl font-semibold'>

                    <span className='graduation-title px-4 py-2'>
                        Weekend Class - 07/28/2024

                    </span>

                </h1>

            </div>
            <div className="md:py-10 lg:w-[80%] w-[95%] mx-auto">
                <div className="lg:mb-8 lg:pt-12 py-5">
                    <h1 className="text-gray-700 text-center  text-xl  advertise md:text-4xl font-semibold dark:text-gray-100">
                        {" "}
                        Graduation Class of 07/28/2024 - Weekend Class
                    </h1>
                    <div className="flex justify-center ">
                        <hr className="bg-primary px-5 md:mt-1 mt-1 py-[1px] w-[100px]" />
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-7">
                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            <ProgressiveImage
                                src={img6}
                                alt={'img1'}
                            />
                        </div>
                        <div
                            class=" row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <ProgressiveImage
                                src={img2}
                                alt={'img2'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img3}
                                alt={'img3'}
                            />
                        </div>
                        <div
                            class="row-span-2 col-span-2 lg:h-[628px] h-[428px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            <ProgressiveImage
                                src={img4}
                                alt={'img4'}
                            />
                        </div>
                    </div>

                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe"
                            data-aos="fade-down"
                            data-aos-duration="1000">

                            <ProgressiveImage
                                src={img5}
                                alt={'img5'}
                            />

                        </div>
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                        >
                            <ProgressiveImage
                                src={img26}
                                alt={'img6'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-up"
                            data-aos-duration="1400"
                        >
                             <ProgressiveImage
                                src={img7}
                                alt={'img7'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe "
                            data-aos="fade-down"
                            data-aos-duration="1400"
                        >
                             <ProgressiveImage
                                src={img8}
                                alt={'img8'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2  lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer  "
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        >
                            <ProgressiveImage
                                src={img9}
                                alt={'img9'}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-7 mt-[28px]">
                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            <ProgressiveImage
                                src={img10}
                                alt={'img10'}
                            />
                        </div>
                        <div
                            class=" row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                             <ProgressiveImage
                                src={img11}
                                alt={'img11'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1400"
                        >
                            <PhotoProvider>
                            <ProgressiveImage
                                src={img12}
                                alt={'img12'}
                            />
                            </PhotoProvider>
                        </div>
                        <div
                            class="row-span-2 col-span-2 lg:h-[628px] h-[428px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            <ProgressiveImage
                                src={img13}
                                alt={'img13'}
                            />
                        </div>
                    </div>

                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                             <ProgressiveImage
                                src={img14}
                                alt={'img14'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                        >
                            <ProgressiveImage
                                src={img15}
                                alt={'img15'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-up"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img16}
                                alt={'img16'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe "
                            data-aos="fade-down"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img17}
                                alt={'img17'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2  lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer  "
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            <ProgressiveImage
                                src={img18}
                                alt={'img18'}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-7 mt-[28px]">
                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                           <ProgressiveImage
                                src={img20}
                                alt={'img20'}
                            />
                        </div>
                        <div
                            class=" row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <ProgressiveImage
                                src={img19}
                                alt={'img19'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img21}
                                alt={'img21'}
                            />
                        </div>
                        <div
                            class="row-span-2 col-span-2 lg:h-[628px] h-[428px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                             <ProgressiveImage
                                src={img22}
                                alt={'img22'}
                            />
                        </div>
                    </div>

                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            
                            <ProgressiveImage
                                src={img23}
                                alt={'img23'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                        >
                            <ProgressiveImage
                                src={img1}
                                alt={'img24'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-up"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img24}
                                alt={'img25'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe "
                            data-aos="fade-down"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img25}
                                alt={'img26'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2  lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer  "
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                           <ProgressiveImage
                                src={img27}
                                alt={'img27'}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-7 mt-[28px]">
                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                           <ProgressiveImage
                                src={img29}
                                alt={'img29'}
                            />
                        </div>
                        <div
                            class=" row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <ProgressiveImage
                                src={img30}
                                alt={'img30'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-right"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img31}
                                alt={'img31'}
                            />
                        </div>
                        <div
                            class="row-span-2 col-span-2 lg:h-[628px] h-[428px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                             <ProgressiveImage
                                src={img37}
                                alt={'img32'}
                            />
                        </div>
                    </div>

                    <div class="grid grid-rows-3 grid-flow-col gap-7">
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                        >
                            
                            <ProgressiveImage
                                src={img32}
                                alt={'img33'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                        >
                            <ProgressiveImage
                                src={img33}
                                alt={'img33'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer "
                            data-aos="fade-up"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img34}
                                alt={'img25'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointe "
                            data-aos="fade-down"
                            data-aos-duration="1400"
                        >
                            <ProgressiveImage
                                src={img35}
                                alt={'img35'}
                            />
                        </div>
                        <div
                            class="row-span-1 col-span-2  lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer  "
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                           <ProgressiveImage
                                src={img36}
                                alt={'img36'}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Weekend07282024;
