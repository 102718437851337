import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthProvider';
import Loading from '../../Shared/Loading';
import useTitle from '../../Hooks/useTitle';

const Register = () => {

    useTitle("Register")

    const [regError, setRegError] = useState('')
    const [loading, setLoading] = useState(null)
    const [showPass, setShowPass] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const { createUser, updateUser } = useContext(AuthContext);
    const imageHostKey = process.env.REACT_APP_imgbb_key;
    const date = Date.now();

    const navigate = useNavigate();

    const handelSignUp = data => {
        setRegError('')
        setLoading(true)
        const image = data.url[0]
        const formData = new FormData();
        formData.append('image', image);
        const url = `https://api.imgbb.com/1/upload?key=${imageHostKey}`

        fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(imgData => {
                // console.log(imgData.data.url)
                if (imgData.success) {


                    createUser(data.email, data.password)
                        .then(result => {
                            const user = result.user
                            // console.log(user)
                            toast.success('user create successfully')
                            setLoading(false)

                            const userInfo = {
                                displayName: data.name,
                                photoURL: imgData.data.url
                            }
                            updateUser(userInfo)
                                .then(() => {
                                    // console.log('update successfully')
                                    saveUser(data.name, data.email, imgData.data.url)

                                    reset();
                                    navigate('/')
                                })
                                .catch(err => console.log(err));

                        })
                        .catch(error => {
                            console.error(error)
                            setRegError(error.message)
                            setLoading(false)

                        })

                }
            })

        // console.log(data)

        const saveUser = (name, email, photoURL, role = "", verify = "false",time=date) => {
            const user = { name, email, photoURL, role, verify,time };
            fetch('https://brooklyn-server-v4.vercel.app/users', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(user)
            })
                .then(res => res.json())
                .then(data => {
                    // console.log('save-user', data)


                })



        }
    }

    return (
        <div className='mt-10'>
            <section className="bg-gray-100 dark:bg-slate-600">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 -mt-14">
                    {
                        loading &&
                        <div className='my-5'>

                            <Loading></Loading>
                        </div>
                    }

                    <div className="w-full bg-white rounded-lg shadow-lg  md:mt-0 sm:max-w-md xl:p-0 dark:bg-slate-800">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-100">
                                Create and account
                            </h1>
                            <form

                                onSubmit={handleSubmit(handelSignUp)}
                                className="space-y-4 md:space-y-6  " >
                                <div>
                                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-100">Your Name</label>

                                    <input

                                        {...register("name", {
                                            required: "Name is required",
                                        })}



                                        type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#CA4757]-600 focus:border-[#CA4757]-600 block w-full p-2.5  " placeholder="Your Name" />

                                    {errors.name && <p className='text-red-600'>{errors.name.message}</p>}

                                </div>

                                <div className="space-y-0.5 text-sm">
                                    <div className='flex items-center gap-4'>
                                        <label htmlFor="firstName" className="block dark:text-gray-400">
                                            Image
                                        </label>

                                        <small className='text-red-500'>[ Image should not be large then 200 KB ] </small>
                                    </div>

                                    <input type="file"

                                        {...register("url", {
                                            required: "Image is required",
                                        })}
                                        className=" bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#CA4757]-600 focus:border-[#CA4757]-600 block w-full p-2.5" />

                                    {errors.url && <p className='text-red-600'>{errors.url.message}</p>}
                                </div>
                                <div>
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-100">Your email</label>

                                    <input

                                        {...register("email", {
                                            required: "Email is required",
                                        })}

                                        type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#CA4757]-600 focus:border-[#CA4757]-600 block w-full p-2.5   " placeholder="Your Email" />

                                    {errors.email && <p className='text-red-600'>{errors.email.message}</p>}
                                </div>
                                <div>
                                    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-100">Password</label>

                                    <input

                                        {...register("password", {
                                            required: "Password is required",
                                        })}


                                        type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#CA4757]-600 focus:border-[#CA4757]-600 block w-full p-2.5" />

                                    {errors.password && <p className='text-red-600'>{errors.password.message}</p>}
                                </div>


                                <button type="submit" className="w-full text-white bg-[#CA4757] hover:bg-[#CA4757]-700 focus:ring-4 focus:outline-none focus:ring-[#CA4757]-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Create an account</button>
                                <p className="text-sm font-light mt-3 text-gray-500 dark:text-gray-100">
                                    Already have an account? <Link to='/login' className="font-medium text-[#CA4757]-600 hover:underline">Login here</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default Register;