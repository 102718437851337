import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";


const DynamicAlbum = () => {
  const album = useLoaderData();
  // console.log(album);



  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const renderImageGrid = () => {
    const chunks = chunkArray(album.images, 9); // Assuming 9 images per chunk
    return chunks.map((chunk, chunkIndex) => (
      <div
        key={chunkIndex}
        className="grid md:grid-cols-2 gap-7 md:py-10 lg:w-[80%] w-[95%] mx-auto"
      >
        {/* Left grid */}
        <div className="grid grid-rows-3 grid-flow-col gap-7">
          {/* Image 1: Full width */}
          <div
            className="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <PhotoView src={chunk[0]}>
              <img
                src={chunk[0]}
                alt={`img-1`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>

          {/* Image 2 & 3: Half width */}
          <div
            className="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <PhotoView src={chunk[1]}>
              <img
                src={chunk[1]}
                alt={`img-2`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>
          <div
            className="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-right"
            data-aos-duration="1400"
          >
            <PhotoView src={chunk[2]}>
              <img
                src={chunk[2]}
                alt={`img-3`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>

          {/* Image 4: Full height across 2 rows */}
          <div
            className="row-span-2 col-span-2 lg:h-[628px] h-[428px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-up"
            data-aos-duration="1600"
          >
            <PhotoView src={chunk[3]}>
              <img
                src={chunk[3]}
                alt={`img-4`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>
        </div>

        {/* Right grid */}
        <div className="grid grid-rows-3 grid-flow-col gap-7">
          {/* Image 5: Half width */}
          <div
            className="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <PhotoView src={chunk[4]}>
              <img
                src={chunk[4]}
                alt={`img-5`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>

          {/* Image 6: Full width across 4 columns */}
          <div
            className="row-span-1 col-span-4 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <PhotoView src={chunk[5]}>
              <img
                src={chunk[5]}
                alt={`img-6`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>

          {/* Image 7-9: Half width */}
          <div
            className="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            <PhotoView src={chunk[6]}>
              <img
                src={chunk[6]}
                alt={`img-7`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>
          <div
            className="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-down"
            data-aos-duration="1600"
          >
            <PhotoView src={chunk[7]}>
              <img
                src={chunk[7]}
                alt={`img-8`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>
          <div
            className="row-span-1 col-span-2 lg:h-[300px] h-[200px] overflow-hidden w-full cursor-pointer relative"
            data-aos="fade-up"
            data-aos-duration="1800"
          >
            <PhotoView src={chunk[8]}>
              <img
                src={chunk[8]}
                alt={`img-9`}
                className="w-full h-[100%] shadow-md border-[1px] border-primary object-cover custom-zoom"
                loading="lazy"
              />
            </PhotoView>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="dynamic-album pb-5 min-h-screen dark:bg-slate-600">
      <div
        className="min-h-[80vh] relative"
        style={{
          backgroundImage: `url(${album?.coverImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="lg:pt-[65vh] pt-[55vh] text-center text-white text-2xl md:text-5xl font-semibold">
          <span className="graduation-title px-4 py-2">{album?.albumName && album?.albumName.replace(/(\d{2})-(\d{2})-(\d{4})/, (match, p1, p2, p3) => `${p1}/${p2}/${p3}`)}</span>
        </h1>
      </div>

      <div className="lg:mb-8 lg:pt-12 py-5">
        <h1 className="text-gray-700 text-center text-xl advertise md:text-4xl font-semibold dark:text-gray-100">
          Graduation Class of  {album?.albumName && album?.albumName.replace(/(\d{2})-(\d{2})-(\d{4})/, (match, p1, p2, p3) => `${p1}/${p2}/${p3}`)}
        </h1>
        <div className="flex justify-center ">
          <hr className="bg-primary px-5 md:mt-1 mt-1 py-[1px] w-[100px]" />
        </div>
      </div>

      <PhotoProvider>{renderImageGrid()}</PhotoProvider>
    </div>
  );
};

export default DynamicAlbum;
