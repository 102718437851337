import React, { useState, useEffect } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const ProgressiveImage = ({ src, alt }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setLoaded(true);
    }, [src]);

    return (

        <PhotoProvider>
            <PhotoView src={src}>
                <img
                className='w-full h-[100%] shadow-md border-[1px] border-primary  object-cover '
                    src={src}
                    alt={alt}
                    style={{
                        transition: 'filter 0.5s ease-out, transform 0.5s ease-out ',
                        filter: `${!loaded ? 'blur(8px)' : ''}`,
                        transform: `${!loaded ? 'scale(1.1)' : 'scale(1) '}`,
                        
                    }}
                />

            </PhotoView>

        </PhotoProvider>


    );
};

export default ProgressiveImage;
