import styled from 'styled-components';

export const Heading = styled.h1`
text-align: center;
`;

export const Content = styled.div`
overflowY: scroll;
`;

export const Button = styled.div`
position: fixed;
left: 1%;
bottom: 22px;
z-index:500;
cursor: pointer;
`
